.cookiebanner {
  position: fixed;
  bottom: 0;
  background-color: darken($yo-medium-blue, 20);
  color: $white;
  width: 100%;
  text-align: center;
  font-size: rem-calc(12);

  button {
    font-weight: bold;
    text-decoration: underline;
    color: $white;
  }
  button:hover {
    color: $yes-primary-color;
    cursor: pointer;
  }

  @include breakpoint(small) {
    text-align: left;
    padding: rem-calc(5);
  }

  @include breakpoint(medium) {
    text-align: center;
    padding: rem-calc(12);
    padding-right: rem-calc(120);
  }

  @include breakpoint(large) {
    text-align: center;
    padding-right: rem-calc(230);
  }
}
