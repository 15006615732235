//TODO remove all importants props when old app graphic design will be entirely remove.
.margin_bottom_1 {
  margin-bottom: 1rem !important;
}

.margin_O {
  margin: 0 !important;
}

.padding_0 {
  padding: 0 !important;
}