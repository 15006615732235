/*
  -- Barre de navigation pour desktop --
*/

$header-context: 18; // Default

@function em($pixels, $context: $header-context) {
  @return #{$pixels/$context}em;
}

.desktop-topnav__topbar-container {
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: all 0.25s;

  .top-bar {
    background: none;
    border-bottom: 1px solid $yo-dark-blue;
    padding: rem-calc(28) 0 0 0;
    transition: all 0.25s;

    .top-bar-right {
      .menu {
        margin-top: 2px;
        margin-bottom: 19px;

        & > li {
          position: relative;
        }
        .topbar-menu__link-account {
          display: flex;
          align-items: center;
          color: #ef6f6c;
          margin-right: rem-calc(15);
          cursor: pointer;
          outline: none;

          &:before {
            content: "";
            display: inline-block;
            background: url(/assets/img/icons/icn-profil.svg);
            background-size: cover;
            width: rem-calc(15);
            height: rem-calc(15);
            margin-right: rem-calc(4);
          }
          &:after {
            content: "";
            display: inline-block;
            background: url(/assets/img/icons/icn-arrow-down.svg);
            background-size: cover;
            width: rem-calc(12);
            height: rem-calc(12);
            margin-left: rem-calc(6);
            margin-top: rem-calc(1);
            transform: rotate(0);
            transition: transform 0.3s;
          }
          &--open:after {
            transform: rotate(180deg);
          }
        }
        .topbar-menu__account-access {
          position: absolute;
          top: rem-calc(35);
          left: 0;
          z-index: 10;
          background: $verylight-blue;
          box-shadow: rem-calc(4) rem-calc(4) rem-calc(3) 0 rgba(0, 0, 0, 0.1);
          width: 100%;
          padding: rem-calc(10) 0;
          list-style: none;
          margin: 0;
          opacity: 0;
          display: none;
          pointer-events: none;

          & > li {
            padding: 0;

            & > a {
              color: rgba(55, 104, 129, 0.7);
              transition: color 0.3s;
              text-decoration: none;

              &:hover {
                color: #ef6f6c;
              }
            }
          }
        }
        .topbar-menu__link-account--open + .topbar-menu__account-access {
          @keyframes OpenAccountAccess {
            0% {
              display: none;
              top: rem-calc(30);
              opacity: 0;
            }
            100% {
              top: rem-calc(40);
              opacity: 1;
              pointer-events: initial;
            }
          }
          display: block;
          animation: OpenAccountAccess 0.4s;
          animation-fill-mode: forwards;
          pointer-events: initial;
        }
      }
      .button.large {
        /* Identique à celui sur Yomoni - A refacto */
        font-size: 0.83333rem;
        letter-spacing: 1.1px;
        padding: 0.7rem 1.2rem;
      }
    }
    ul {
      background: none;
    }
  }

  .topbar-menu__link {
    opacity: 1;
    color: $yes-primary-color-2;
    font-family: $yo-content-font;
    font-size: rem-calc(15);
    font-weight: normal;
    letter-spacing: 0.4px;
    margin: 0 rem-calc(15);
    padding: rem-calc(16) 10px rem-calc(29) 10px;
    // Quand le lien n'est pas actif on met une bordure transparente pour que la hauteur reste fixe
    text-decoration: none;
    transition: border 0.5s ease-out, padding 0.25s ease-out;

    &:hover {
      opacity: 0.7;
    }
  }

  .topbar-menu__link--currentpage {
    position: relative;
    color: $yes-grey-blue;
    font-size: rem-calc(16);
    font-weight: 600;
    opacity: 1;
    padding: 0 rem-calc(15);

    &:after {
      content: "";
      position: absolute;
      bottom: rem-calc(-10);
      left: 50%;
      background: $yes-grey-blue;
      border-radius: 0;
      width: rem-calc(50);
      height: rem-calc(2);
      margin-left: rem-calc(-25);
      padding: 0;
      transform: inherit;
    }
  }

  .topbar-menu__logolink {
    margin-right: rem-calc(10) !important;
  }

  .topbar-menu__button {
    font-size: rem-calc(15);
    letter-spacing: 1.1px;
    // Surcharge la variable $global-menu-padding normalement appliquée à tous les éléments du menu
    padding: 0.7rem 1.2rem;
  }
}

.desktop-topnav__topbar-container {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 rem-calc(4) rem-calc(3) 0 rgba(0, 0, 0, 0.1);
  height: rem-calc(80);
  z-index: 300;

  .grid-container {
    width: 100%;
    padding-left: rem-calc(25);
    padding-right: rem-calc(25);
  }
  .top-bar {
    border-bottom: 0;
    padding: 0;

    .top-bar-left {
      display: flex;
      flex-wrap: wrap;
      height: rem-calc(26);
      overflow: hidden;

      .menu,
      li {
        padding-bottom: rem-calc(10);
      }
    }
    .top-bar-right {
      display: flex;
      justify-content: flex-end;
      flex: none;
      width: rem-calc(420);

      .menu,
      li {
        padding-bottom: rem-calc(4);
      }
    }
    .top-bar-right .menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;

      .topbar-menu__link {
        padding: 0;
        margin-right: rem-calc(15);
      }
      .button.secondary {
        background: #ef6f6c;
      }
    }
  }
  .topbar-menu__link {
    margin: 0;
    padding: 0 rem-calc(15);
    font-size: rem-calc(16);
  }
  .topbar-menu__logolink {
    width: inherit;
    height: inherit;
    padding: 0 !important;
  }
}

.topbar-menu__logolink .topbar-menu__logo {
  width: rem-calc(38);
  height: rem-calc(48);
}

// Modifie la barre de nav desktop au scroll
.desktop-topnav__topbar-container--scrolled {
  box-shadow: -1px 1px 2px 0 rgba(66, 86, 104, 0.23);
  height: rem-calc(70);

  .top-bar {
    border-bottom: 0 solid;
    padding: rem-calc(2) 0 0 0;

    .top-bar-right .menu {
      margin-top: rem-calc(9);
      margin-bottom: rem-calc(11);
    }
  }

  .topbar-menu__logolink {
    padding: 0 0 rem-calc(30) 0 !important; // Hack pour centrer verticalement le logo
    margin-top: rem-calc(28);
  }
}

/*
 Prend juste la place sous la topbar qui est en fixed pour positionner correctement
 le hero quand on est en haut de la page
*/
.desktop-topnav__inlay {
  height: rem-calc(91);
  background-color: white; //white;
  width: 100%;
}

/*
    -- Barre de navigation pour mobile --
*/
.mobile-topnav__topbar-stickymenu {
  background-color: white;
  border-bottom: solid 1px $yo-light-grey;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  .top-bar {
    width: 100%;
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);

    img {
      width: rem-calc(35);
    }
  }

  // Assombrit légèrement le blason yomoni au click
  a:active > img {
    filter: brightness(80%);
  }

  .topbar-menu__logo {
    width: rem-calc(38);
    height: rem-calc(48);
  }
}

/*
    --- Bouton animé hamburger ---
    Inspiré de http://callmenick.com/post/animating-css-only-hamburger-menu-icons
    et https://github.com/callmenick/Animating-Hamburger-Icons/blob/master/sass/_components.hamburger.scss
*/
$ham-button-width: 27px; // The width of the button area
$ham-button-height: 35px; // The height of the button area
$ham-bar-thickness: 2px; // The thickness of the button bars
$ham-button-pad: 8px; // The left/right padding between button area and bars.
$ham-button-bar-space: 4px; // The spacing between button bars
$ham-button-transition-duration: 0.4s; // The transition duration
$ham-button-transition-step: ($ham-button-transition-duration / 3);
.mobile-topnav__hamburger {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: $ham-button-width;
  height: $ham-button-height;
  font-size: 0;
  text-indent: -9999px;
  box-shadow: none;
  border-radius: none;
  border: none;
  outline: none;
  cursor: pointer;
  // Désactive le calque bleu transparent au clique sur le bouton
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  span {
    border-radius: $ham-bar-thickness;
    display: block;
    position: absolute;
    top: ($ham-button-height / 2) - ($ham-bar-thickness / 2);
    left: $ham-button-pad;
    right: 0;
    height: $ham-bar-thickness;
    background: $brand-color;
    transition: background-color 0s ($ham-button-transition-step * 1);
  }

  span::before,
  span::after {
    border-radius: $ham-bar-thickness;
    position: absolute;
    display: block;
    height: $ham-bar-thickness;
    background-color: $brand-color;
    content: "";
  }

  span::before {
    left: -$ham-button-pad;
    right: 0;
    transition: transform $ham-button-transition-step,
      top $ham-button-transition-step $ham-button-transition-step,
      left $ham-button-transition-step ($ham-button-transition-step * 2);
    top: -$ham-bar-thickness - $ham-button-bar-space;
  }

  span::after {
    left: $ham-button-pad;
    right: 0;
    transition: transform $ham-button-transition-step,
      bottom $ham-button-transition-step $ham-button-transition-step,
      left $ham-button-transition-step ($ham-button-transition-step * 2);
    bottom: -$ham-bar-thickness - $ham-button-bar-space;
  }
}

// Anime les traits du hamburger pour le transformer en croix
.mobile-topnav__hamburger--is-active {
  span {
    background: none;
    transition: background-color 0s ($ham-button-transition-step * 2);
  }

  span::before {
    left: 0;
    top: 0;
    transition: left $ham-button-transition-step,
      top $ham-button-transition-step $ham-button-transition-step,
      transform $ham-button-transition-step ($ham-button-transition-step * 2);
    transform: rotate(45deg);
  }

  span::after {
    transition: left $ham-button-transition-step,
      bottom $ham-button-transition-step $ham-button-transition-step,
      transform $ham-button-transition-step ($ham-button-transition-step * 2);
    left: 0;
    bottom: 0;
    transform: rotate(-45deg);
  }
}

/*
    --- Menu glissant par la droite sur mobile ---
    Inspiré de http://callmenick.com/post/slide-and-push-menus-with-css3-transitions
*/
// Masque pour assombrir le corps de la page
.mobile-topnav__mask {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  height: 0;
  background-color: $denim;
  opacity: 0;
  transition: opacity 0.3s, width 0s, height 0s;
}

.mobile-topnav__mask--is-active {
  width: 100%;
  height: 100%;
  opacity: 0.63;
  transition: opacity 0.3s, width 0s, height 0s;
}

.mobile-topnav__slidingmenu {
  // Taille du menu sticky + bandeau "se connecter"
  padding-top: rem-calc(50);
  background-color: $denim;
  position: fixed;
  // (valeur étrange du z-index pour passer au dessus du chat zendesk)
  z-index: 16000002;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: right 0.3s;

  ul {
    list-style-type: none;
    margin: rem-calc(40);
  }
  li {
    margin-bottom: rem-calc(27);
  }

  @media only screen and (orientation: landscape) {
    li {
      margin-right: rem-calc(40);
      margin-bottom: rem-calc(20);
      display: inline-block;
    }
  }

  a {
    font-family: $yo-content-font;
    color: $yo-white;
    font-size: rem-calc(14);
    letter-spacing: 1px;
    //font-weight: 600;
    text-transform: uppercase;
  }

  .mobile-topnav__bottom-actions {
    width: 100%;
    text-align: center;
    margin-bottom: rem-calc(40);

    a {
      display: block;
    }

    .button.secondary.small {
      max-width: rem-calc(250);
      margin: auto;
      margin-top: rem-calc(20);
      width: 100%;
      font-size: rem-calc(15);
      letter-spacing: 0.9px;
      padding: rem-calc(15) rem-calc(10);
      background: #ef6f6c;
    }
  }

  // Sur small, on slide par la droite
  @include breakpoint(small only) {
    transform: translateX(100%);
    transition: right 0.3s;
    width: 75%;
    height: 100%;
  }

  // Sur medium, on slide par le haut et on met les items sur la même ligne
  @include breakpoint(medium) {
    transform: translateY(-100%);
    transition: top 0.3s;
    width: 100%;

    li {
      margin-right: rem-calc(40);
      margin-bottom: rem-calc(20);
      display: inline-block;
    }
  }
}

.mobile-topnav__slidingmenu--is-active {
  @include breakpoint(small only) {
    right: 75%;
  }
  @include breakpoint(medium only) {
    top: rem-calc(313);
  }
}

/*
Bandeau "se connecter" + bouton du menu en haut de la page
*/
.mobile-topnav__connectbar-container {
  box-shadow: -1px 1px 2px 0 rgba(66, 86, 104, 0.23);
  // (valeur étrange du z-index pour passer au dessus du chat zendesk)
  z-index: 16000003;
  // Place prise par le bandeau sticky
  padding-top: rem-calc(53);
  position: relative;
  .top-bar {
    z-index: 150;
    margin: 0 15px 0 15px;
  }

  .topbar-menu__logolink {
    font-size: rem-calc(12);
    color: $yo-medium-blue;
    //font-weight: 600;
    letter-spacing: 0.9px;
  }
}
