// VARIABLES POUR GENERALI EPARGNE SALARIALE :
$yes-color-text: #6f7072;
$yes-color-title: #6f7072;
$yes-primary-color: #752127;
$yes-secondary-color: #752127;
$yes-primary-color-hover: rgba(117, 33, 39, 0.8);
$yes-primary-color-2: #376881;
$yes-primary-color-3: rgba(104, 178, 187, 0.1);
$yes-primary-color-4: #f8f9fb;
$yes-gris1: rgba(66, 86, 104, 0.8);
$yes-gris1-hover: rgba(66, 86, 104, 1);
$grid-container: 1200px;
$yes-grey-blue: #68b2bb;
$yes-bluish-grey: #728d9c;
$yes-light-green-transparent: rgba(104, 178, 187, 0.04);
$denim: #376881;
$yes-checkbox-disabled-color: $yes-primary-color;
$yes-input-background-color: #f8f9fb;
$yes-input-background-enabled: white;
$yes-input-border-bottom: $yes-primary-color;
$yes-checkbox-border: $yes-primary-color;
$yes-slider-trace-color: #c21b17;
$yes-slider-cursor-fill: #e95730;
$yes-slider-cursor-border-color: #e8d6d6;
$yes-slider-range-color: #e8d6d6;
$yes-circle-check-color: rgba(117, 33, 39, 0.3);
$yes-libelle-retour-color: #6f7072;
$yes-entreprise-card-part2-color: #6f7072;
$yes-info-etape-menu: $yes-primary-color;
$yes-label: #6f7072;
$font-text: 'Source Sans Pro', 'sans-serif';
$yes-retour-button-color: #bf252b;
$yes-button-primary-color: $yes-retour-button-color;