.button {
  transition: background 0.3s, transform 0.3s;
  will-change: transform;

  &:hover {
    box-shadow: 0 4px 17px rgba(0, 0, 0, 0.2);
    transform: translate3d(0, -2px, 0);
  }
  &:focus {
    background-color: $yes-primary-color !important;
  }
  &:active {
    box-shadow: 0 0 0;
    transform: translate3d(0, 0, 0);
  }
}