.popInDeconnexion {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  width: 40%;
  height: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: $yes-primary-color-hover;
  background-color: $yes-primary-color-4;
  border-radius: rem-calc(20);
  z-index: 1;
  color: $yes-primary-color;
  padding: 20px;
  .text-aide {
    font-style: italic;
  }
  .timer {
    font-size: 30px;
  }
}
