/**
Contient toutes les surcharges de déclaration CSS qu'il n'a pas été possible d'adapter en modifiant les variables
foundation
*/

@include breakpoint(small only) {
  body {
    font-size: rem-calc(15);
  }
}

.button {
  text-transform: uppercase;
  letter-spacing: 1.1px;
  box-shadow: 4px 4px 3px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.07s ease-in-out;
}

.button:active {
  transform: translateX(rem-calc(1)) translateY(rem-calc(1));
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
}

h3,
h4 {
  font-family: $yo-content-font;
}

a {
  span.sr-only {
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    top: -10px;
  }
}

a.button:hover {
  text-decoration: none;
}

// -- Personnalisation de l'accordéon pour placer le plus à gauche du titre --
.accordion-title {
  padding-left: 2.5rem;
  font-size: rem-calc(20);
  color: $yo-dark-grey;
  line-height: 1.3;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  @include breakpoint(small only) {
    font-size: rem-calc(15);
    padding-left: 1rem;
  }
}

.accordion-content {
  ul {
    margin-top: rem-calc(10) !important;
    margin-bottom: rem-calc(0) !important;

    li {
      margin-bottom: rem-calc(20);
    }
  }
}

@include breakpoint(small only) {
  .accordion-content {
    padding: 0rem;
  }

  .accordion-item {
    margin: 1.2rem 0 !important;
  }
}

.accordion-title::before {
  top: 1.1rem;
  left: -0.2rem;
  border-top: solid 2px $yo-medium2-grey;
  border-right: solid 2px $yo-medium2-grey;
  width: 0.8rem;
  height: 0.8rem;
  transform: rotate(45deg);
  content: " ";
  transition: transform 0.2s ease-out, left 0.2s ease-out, color 0.2s ease-out;

  @include breakpoint(small only) {
    width: 0.5rem;
    height: 0.5rem;
  }
}

.reveal,
.reveal-overlay {
  z-index: 16000005;
}

.is-active {
  .accordion-title {
    color: $yo-blue;
  }
  .accordion-title::before {
    content: " ";
    transform: rotate(135deg);
    border-color: $yo-blue;
    left: 0;
  }
}
