html,
body,
#root {
  background: #fff;
  height: 100%;
}

.App-compte {
  text-align: center;
  max-width: 100%;
  margin: 0 auto;
  height: 100vh;
}

.yes-illustration-container {
  display: inline-block;
  width: 300px;
  height: auto;
  vertical-align: top;
  background: $yes-primary-color-3;
  position: relative;

  @include breakpoint(medium) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
  }
  .conditionsUtilisations a {
    color: $yes-color-text;
  }

  .infosContactTunnel {
    position: absolute;
    right: 4%;
    top: 5%;
  }

  .helper-illu {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .conditionsUtilisations {
    font-family: $font-text;
    font-size: rem-calc(12);
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #728d9c;
    position: absolute;
    bottom: 20px;
    left: 23px;
  }

  .illu {
    width: 70%;
  }
}

.tunnel-container {
  display: inline-block;
  text-align: left;
  min-height: 100%;
  height: auto;
  padding-right: 3.5rem;
  padding-left: 3.5rem;
  background: white;

  @include breakpoint(medium) {
    background: #fff;
    margin: 0;
  }
  .tunnel-ctn {
    padding: 1rem;
  }

  .grid-x {
    height: 100%;
  }

  .App-intro {
    height: 100%;
    padding-bottom: rem-calc(25);
  }

  .formConnexion .errorMsgInput {
    max-width: 85%;
  }

  .errorMsgInput {
    position: relative;
  }

  .formConnexion button,
  .formCreation button {
    background: $yes-secondary-color;
  }

  .formConnexion button.yes-button.yes-button-disabled:hover,
  .formCreation button.yes-button.yes-button-disabled:hover {
    background: $yes-secondary-color;
    opacity: 0.5;
  }

  .formConnexion button.yes-button:hover,
  .formCreation button.yes-button:hover {
    background: $yes-secondary-color;
    opacity: 0.8;
  }

  .formConnexion {
    padding-top: rem-calc(0);
  }

  .formCreation .custom-label-politique-confid {
    display: inline;
    margin-left: 0;
    bottom: 2px;
  }

  .formCreation .divCheckbox {
    display: inline-block;
  }

  .hideCustomErrorInputForCompte .inputEnabled .errorMsgInput {
    display: none;
  }

  .customErrorMsgCompte {
    padding-left: rem-calc(11);
    padding-top: rem-calc(4);
  }

  .formMdpOublie {
    padding-top: rem-calc(0);

    .mpdOublieMailEnvoye {
      font-weight: 600;
    }
  }

  .yes-header-right,
  .yes-footer-right,
  .illuCompteContainer {
    background: $yes-primary-color-3;
  }

  .imageContainer {
    // margin-top: rem-calc(31);
    // margin-bottom: rem-calc(42);

    .logoTunnelYomoni {
      width: 173px;
      //height: 80px;
      padding-bottom: rem-calc(25);
    }
  }

  .illuCompteContainer {
    text-align: center;

    .illu {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      height: auto;
    }
  }

  .buttonCreationContainer {
    margin-top: rem-calc(30);

    .connectezVous {
      display: inline-block;
      margin-left: rem-calc(24);
      margin-top: rem-calc(20);
    }
  }
}

// CSS FOR MOBILE
.pageSlider {
  div:first-child {
    left: 0;
    background: white !important;
    transition: all 0.3s linear 0s !important;

    .grid-x.grid-margin-x {
      display: block;
    }

    div:last-child {
      bottom: 0 !important;
      top: 0 !important;
      left: 0 !important;
      transform: translate(-0%, -0%) !important;
      // min-height: 100%;
      width: 100%;

      .buttonCreationContainer {
        margin-left: 12px;
        margin-top: 0px;
        position: absolute;
        top: auto !important;
        // bottom: 16px !important;
        width: calc(100% - 29px);
      }

      .yes-header-mobile {
        background: $yes-primary-color !important;
        height: 35px;

        img {
          height: 30px;
          width: auto;
          padding-left: 13px;
          padding-top: 6px;
        }
      }

      form {
        padding: rem-calc(15);
        min-height: calc(100vh - 143px);
      }
    }
  }
}

.yes-arrow-mobile {
  margin-top: 32px;
  margin-bottom: 0px;
  z-index: 10;

  img {
    position: absolute;
    right: 10px;
    height: 55px;
    width: auto;
    height: 55px;
    cursor: pointer;
  }
}

@media screen and (max-width: 480px) {
  .tunnel-container {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;

    .formMdpOublie,
    .formChangementMdpOublie {
      .buttonCreationContainer {
        margin-bottom: rem-calc(20);
      }

      .titreFormulaire {
        margin-top: rem-calc(30);
      }

      .divInput {
        margin-top: rem-calc(14);
      }
    }

    .tunnel-ctn {
      padding: 0;
      align-content: start;

      .App-intro {
        padding-bottom: 0;
      }

      .buttonCreationContainer {
        margin-top: rem-calc(20);
        position: absolute;
        width: calc(100% - 35px);
        bottom: 25px;
        // z-index: -1;

        .buttonCreationContent {
          top: auto !important;
          min-height: auto !important;
          position: absolute;
          bottom: 20px !important;
        }

        button {
          // width: calc(100% - 35px);
        }

        span {
          display: block;

          button {
            width: 100%;
          }
        }

        .connectezVous {
          margin-left: 0px;
          margin-top: rem-calc(11);
        }
      }
    }

    .imageContainer {
      text-align: center;
      margin-top: rem-calc(17);
      text-align: center;
      height: rem-calc(75);

      img.logoTunnelYomoni {
        padding-bottom: 0px;
        width: 60%;
        max-width: 190px;
        // padding-bottom: 40px;
        box-sizing: border-box;
      }
    }
  }

  .yes-illustration-container {
    margin-left: rem-calc(16);
    width: 40%;
    position: relative;
    background: white;
    width: 50%;
    bottom: rem-calc(115);

    .illu {
      display: inline-block;
      vertical-align: middle;
      width: auto;
    }
  }

  .yes-illustration-container.illu-creation-compte {
    display: block !important;
    width: 90%;
    // height: 20%;
    background: transparent;
    position: absolute;
    bottom: 0;
    text-align: left;

    .illu {
      width: 50%;
    }

    .infosContactTunnel {
      position: absolute;
      right: 7%;
      top: 40%;
    }
  }

  .buttonCreationContainer {
    text-align: center;
  }
}

@media screen and (min-width: 1450px) {
  .tunnel-container {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
  }
}
