.footer__container {
  background-color: $denim;
  padding: rem-calc(80) 0;
  color: $white;
  font-size: rem-calc(18);

  h3 {
    font-weight: normal;
  }
  p,
  a {
    color: $white;
  }
  hr + p {
    margin-bottom: 0;
  }

  hr {
    border-color: white;
    @include breakpoint(small) {
      width: 80%;
    }
    @include breakpoint(medium) {
      width: 20%;
    }
  }

  @include breakpoint(small only) {
    padding: rem-calc(30);
    font-size: rem-calc(14);
  }

  address {
    font-style: normal;
  }
}

.footer__container + .footer__container {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: relative;
    top: rem-calc(-50);
    border-top: 1px solid $white;
    width: 90%;
    margin: 0 auto;
  }
}

.footer__container--small {
  padding: 100px 0px;
  font-size: rem-calc(18);

  @include breakpoint(small only) {
    padding: rem-calc(25) rem-calc(30);
    font-size: rem-calc(14);
  }
}

.footer--smaller {
  font-size: rem-calc(15);
  line-height: 1.6;
  letter-spacing: 0.5px;
  margin-top: 1rem;

  @include breakpoint(small only) {
    font-size: rem-calc(12);
    line-height: 1.58;
    letter-spacing: 0.4px;
  }
}

// Bloc contact
.footer__contact {
  border-radius: rem-calc(3);
  box-shadow: rem-calc(4) rem-calc(4) rem-calc(3) 0 rgba(0, 0, 0, 0.1);
  background-color: $yes-grey-blue;
  padding: rem-calc(32);
  line-height: 1.45;
  font-style: normal;
  text-align: left;
  min-width: rem-calc(300);

  @include breakpoint(small only) {
    margin: rem-calc(30) 0;
    padding: rem-calc(25) rem-calc(18);
    font-size: rem-calc(16);
  }

  p {
    font-size: rem-calc(20);
    margin: 0;
    line-height: 1.6;

    &:nth-child(4) {
      margin-bottom: rem-calc(20);
    }
  }
}
.footer__contact-image {
  display: block;
  margin-bottom: rem-calc(32);

  @include breakpoint(small only) {
    margin-bottom: rem-calc(18);
  }
}
.footer__contact--telNumber {
  font-weight: bolder;
}

// Bloc des liens de maillage
.footer__links {
  display: block;
  columns: 3;

  @include breakpoint(small only) {
    columns: 2;

    .column {
      break-inside: avoid-column;
    }
  }
  @include breakpoint(medium only) {
    margin-bottom: rem-calc(30);
  }
  .column:nth-child(3) {
    break-inside: avoid;

    @media only screen and (min-width: 1024px) and (max-width: 1028px) {
      position: relative;
      top: rem-calc(-33);
    }
  }
  .column {
    margin-bottom: rem-calc(30);
  }
  h3 {
    font-family: $yo-content-font;
    color: $light-green;
    font-size: rem-calc(20);
    text-transform: uppercase;
    margin: 0 0 rem-calc(20);
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 5px 0;
  }

  a {
    font-size: rem-calc(18);
  }

  @include breakpoint(small only) {
    ul {
      padding: 0 0 rem-calc(30) 0;
    }
  }
  .column:nth-child(3) {
    li:nth-child(1) a,
    li:nth-child(4) a {
      text-decoration: none;
      pointer-events: none;
    }
    li:not(:nth-child(1)):not(:nth-child(4)):not(:last-child) a {
      font-weight: 200;
    }
  }
}

// Bloc liens reseaux sociaux et newsletter
.footer__sociallinks {
  @include breakpoint(medium) {
    padding-bottom: rem-calc(40);
  }
  @include breakpoint(medium only) {
    padding-right: rem-calc(30);
  }

  color: $white;

  h3 {
    font-family: $yo-content-font;
    color: $light-green;
    font-size: rem-calc(20);
    text-transform: uppercase;
    margin: 0 0 rem-calc(20);
  }
}
.footer__sociallinks-logo {
  @include breakpoint(small only) {
    width: rem-calc(35);
  }
}

// Formulaire d'abonnement aux newsletters dans le footer
.footer__form {
  position: relative;
}
.footer__input {
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: rem-calc(4);
  border: rem-calc(1) solid #fff;
  color: #fff;
  font-size: rem-calc(18);
  padding: 10px 5px 10px 15px;
  outline: none;
  height: rem-calc(55);
}
.footer__submit {
  position: absolute;
  top: rem-calc(1);
  right: rem-calc(1);
  background: $yes-grey-blue;
  border-radius: 0 rem-calc(4) rem-calc(4) 0;
  width: rem-calc(53);
  height: rem-calc(53);
  cursor: pointer;
  padding: 5px;
}
.footer__input::placeholder {
  color: white;
  font-size: rem-calc(18);
}
