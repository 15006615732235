.App {
  background: #f7fbfb;
  min-height: 100vh;
}

.App-intro {
  background: white;
}

.button,
.yes-button {
  border-radius: 4px;
  margin-right: 1rem;
}

.item-simulateur-container.effectif-container {
  padding-top: 7%;

  .simulateur-h2 {
    margin-top: rem-calc(45);
  }

  .illuSimulateurContainer {
    display: flex;
    align-items: flex-end;

    .illu-calcul {
      position: absolute;
      bottom: rem-calc(20);
      right: rem-calc(-50);
      max-width: rem-calc(450);

      @media only screen and (max-width: 1023px) {
        position: relative;
        right: 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .buttonValiderInformation {
    margin-bottom: rem-calc(95);
    margin-top: rem-calc(50);
  }
  .informationsForm {
    .divInput {
      margin-top: 10px;
    }
  }
}

.efficacite-entreprise {
  position: relative;
}

.efficacite-entreprise,
.graph-pie {
  border: none;
  box-shadow: none;
  background: white;
}

.graph-pie {
  position: relative;
}

.item-simulateur-container:nth-child(odd) {
  background: white;

  .grid-charges .panelCharges {
    .dropdown,
    input {
      background-color: white;
    }
  }

  .economies-rectangle,
  .curseurs-rectangle,
  .efficacite-entreprise,
  .graph-pie,
  .resultat-global .yes-rectangle,
  .formObjectifs,
  .choixCharges {
    background: $yes-primary-color-4;

    .rectangle-curseurs {
      background: white;

      .inputText {
        background: rgba(237, 241, 244, 0.4);
      }
    }
  }
}

.item-simulateur-container:nth-child(even) {
  background: $yes-primary-color-4;

  .grid-charges .panelCharges {
    .dropdown,
    input {
      background-color: $yes-primary-color-4;
    }
  }

  .formObjectifs {
    background: white;
  }

  .rectangle-curseurs {
    background: $yes-primary-color-4;

    .inputText {
      background: white;
    }
  }
}

.objectif-container,
.participation-container {
  padding-bottom: rem-calc(75);
}

.objectif-container {
  .illus-personnalisation {
    display: flex;
    align-items: center;

    .illu-objectif {
      width: 100%;
    }
  }
}

.participation-container {
  .illu-participation {
    max-width: 85%;
  }
}

.item-simulateur-container.restitution-container {
  padding-bottom: rem-calc(35);

  .marge-gras-2-recommandation {
    margin-top: rem-calc(16);
    margin-bottom: rem-calc(25);
  }

  .btnOuvrirPanelCharges {
    left: 64%;
    top: rem-calc(-3);
    width: auto;
  }
}

.email-container {
  background: $yes-primary-color !important;
  padding-bottom: rem-calc(25) !important;
}

.charges-container {
  padding: 0;
  background: transparent !important;
}

.button-resultat-container {
  text-align: center;
  margin-bottom: rem-calc(30);
  background: transparent !important;
}

.item-simulateur-container.button-resultat-container {
  padding: 0;
}

.item-simulateur-container {
  padding-top: rem-calc(40);

  .yes-rectangle {
    .divInput {
      margin-top: 0;
    }
  }

  .buttonContainer {
    margin-top: rem-calc(40);
  }

  .titres-simulateur {
    max-width: 820px;
  }

  .center-item-simu-container {
    max-width: rem-calc(1200);
    margin: 0 auto;
    position: relative;

    .indication-maj-donnees {
      margin-top: rem-calc(10);
      position: absolute;
      max-width: 65%;
    }

    .h3-with-bottom-marge {
      margin-bottom: rem-calc(40);
    }

    .valeur-non-contractuelle {
      font-family: $font-text;
      font-size: rem-calc(16);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.69;
      letter-spacing: normal;
      color: #89969f;
      margin-top: rem-calc(25);
      margin-bottom: rem-calc(16);
    }

    .formObjectifs {
      border-radius: 4px;
      border: solid 1px #e6eaee;
      padding: 0;

      .choix-objectif {
        display: table;
        padding-top: 4%;
        padding-bottom: 4%;
        padding-right: 9%;
        border-bottom: 1px solid #d6dadf;

        .text-objectif-titre {
          display: table-cell;
          font-size: rem-calc(18);
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #4e627d;
        }

        .radio-objectif {
          display: table-cell;
          height: 100%;
          width: 10%;
          vertical-align: middle;

          .yes-circle {
            margin: 0 auto;
            display: block;
          }
        }

        .text-objectif {
          width: 100%;
          display: inline-block;
          cursor: pointer;
        }
      }

      .choix-objectif.griserObjectif {
        opacity: 0.5;

        .yes-circle input {
          cursor: default;
        }
      }
    }

    .guideText {
      margin-top: rem-calc(17);
    }

    .formParticipation {
      .titreFormulaire {
        margin-top: rem-calc(9);
        margin-bottom: rem-calc(15);
      }

      .choixBenefice {
        margin-top: rem-calc(15);
        margin-bottom: rem-calc(30);

        div {
          display: inline-block;
        }

        .labelChoix {
          padding-right: rem-calc(58);
        }
      }

      .connaitreMontantParticipation {
        margin-top: rem-calc(33);

        .divInput {
          margin-top: 0;
          position: relative;
          bottom: 1px;
          left: 12px;
          margin-right: rem-calc(20);
        }

        .montantParticipation {
          margin-bottom: rem-calc(17);
          position: relative;

          div {
            display: inline-block;

            .inputText {
              // background: $yes-primary-color-3;
            }
          }

          .divInputMontantParticipation {
            position: absolute;
            width: auto;
            bottom: 2px;

            .errorMsgInput {
              top: 3px;
              max-width: 100%;
            }
          }

          .yes-montant-participation-calcule {
            position: relative;
            bottom: rem-calc(6px);
          }
        }
      }
      .indication-plafond-participation {
        font-size: rem-calc(15);
      }

      .formule-participation-card {
        padding: rem-calc(10);
        background: rgba(237, 241, 244, 0.4);

        .formule-participation {
          padding-top: rem-calc(15);
          width: 65%;
        }
      }
    }
    .curseurs-rectangle {
      height: 100%;

      .rectangle-curseurs {
        box-shadow: none;
        border: none;
        margin-top: rem-calc(17);
        padding: rem-calc(8);
      }
    }

    .curseurs {
      .titreTypePrime {
        font-family: $font-text;
        font-size: rem-calc(14.5);
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.6;
        letter-spacing: 0.5px;
        color: #425668;
        text-transform: uppercase;

        .input-tooltip {
          text-transform: none;
        }
      }

      .titreTypePrime.titreInt,
      .titreTypePrime.titrePart {
        margin-bottom: rem-calc(20);
        margin-top: rem-calc(20);
      }

      .titreTypePrime.titreInt {
        position: relative;
        margin-top: rem-calc(50);

        .tooltipNoForm {
          position: initial;

          .tooltipSpan {
            bottom: 1px;
            position: absolute;
            right: -4px;
          }
        }
      }

      .titreTypePrime.titrePart {
        margin-top: rem-calc(42);
      }

      .sousTitreTypePrime {
        font-family: $font-text;
        font-size: rem-calc(14.5);
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: 0.6px;
        color: #728d9c;
        margin-bottom: rem-calc(22);
        margin-left: rem-calc(12);
      }

      .infosPrimes {
        font-family: $font-text;
        font-size: rem-calc(16);
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #ffffff;
      }

      .formCurseurs {
        .divInput {
          margin-top: 0;

          .inputText {
            border-color: $yes-primary-color;
            // background: white;
          }
        }
        .sliderDiv {
          padding-top: rem-calc(26);
          padding-bottom: rem-calc(23);
          padding-left: rem-calc(8);
          position: relative;

          .plafondPrime {
            position: absolute;
            right: 0;
            top: -3px;
          }
        }
        .sliderDiv.abondementSlider {
          padding-top: rem-calc(20);

          .plafondPrime {
            top: rem-calc(34);
            bottom: 0;
          }
        }
        .fieldAbondement {
          padding-top: rem-calc(41);
        }
        .percentSalaire {
          font-family: $font-text;
          font-size: rem-calc(14);
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $yes-color-text;
          position: relative;
          right: rem-calc(34);
          min-width: rem-calc(125);
          display: inline-block;
          text-align: right;
        }

        .sliderInt {
          .yes-tooltip {
            position: absolute;
          }
          .input-tooltip {
            display: block;
            bottom: rem-calc(-113);
          }
          .yes-tooltip .tooltip-container .input-tooltip::after {
            bottom: rem-calc(67);
            transform: rotate(138deg);
            margin-left: 0.5em;
            box-shadow: none;
          }
        }
      }

      .rectangle-enveloppe {
        background: #ef6f6c;
        color: white;
        margin-top: rem-calc(35);
        margin-bottom: rem-calc(0);
        box-shadow: none;

        .titreTypePrime {
          margin-top: rem-calc(10);
          color: white;
        }

        .titreTypePrime {
          margin-top: rem-calc(9);
        }

        .divInput {
          .inputText {
            color: white;
            border-color: white;
          }
          .euroSign {
            color: white;
          }
        }
      }
    }
  }

  .graphiques-restitution {
    .enDetails {
      right: rem-calc(20);
    }

    .yes-titre-rectangle {
      margin-top: rem-calc(18);
      margin-bottom: rem-calc(33);
      // padding-left: rem-calc(5);
    }

    .graph-titreTypePrime {
      font-family: $font-text;
      font-size: rem-calc(16);
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 0.6px;
      color: #425668;
      text-transform: uppercase;
    }

    .graph-montantGlobal {
      text-align: right;
      font-family: $font-text;
      font-size: rem-calc(20);
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: 0.7px;
      color: #425668;
      padding-top: rem-calc(14);
      padding-right: rem-calc(10);
    }

    .graph-montantGlobal.yes-green {
      color: $yes-primary-color;
    }

    .graphiques-trait-horizontal {
      width: 80%;
      height: 1px;
      background: rgba(66, 86, 104, 0.3);
      text-align: center;
      margin: 0 auto;
      margin-bottom: rem-calc(21);
    }

    .graph-bar {
      margin-bottom: rem-calc(30);

      .titreGraph {
        margin-bottom: rem-calc(35);
      }

      .graph-titreTypePrime {
        padding-top: rem-calc(17);
        // padding-left: rem-calc(4);
      }

      h3.graph-economie {
        text-align: center;
        margin-top: rem-calc(36);
        margin-bottom: 0;
      }

      .horizontal-bar div {
        display: inline-block;
        color: white;
        text-align: center;
        font-family: $font-text;
        font-size: rem-calc(16);
        // font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: 0.6px;

        .title-graph-montant {
          font-family: $font-text;
          font-size: rem-calc(14);
          font-weight: 300;
          font-style: italic;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $yes-color-text;
          position: absolute;
          bottom: rem-calc(53);
        }
      }
      .graph-env-ind,
      .graph-charges {
        padding-top: rem-calc(13);
        position: relative;
        min-height: rem-calc(52);
        vertical-align: top;
        white-space: nowrap;

        .graph-total-cout-ep {
          position: absolute;
          color: #bedde1;
          left: calc(100% + 4px);
          text-align: left;
          width: auto;
        }
      }
      .horizontal-bar.graph-primeClassique {
        margin-bottom: rem-calc(25);

        .graph-env-ind {
          background-color: #ef6f6c;
        }
        .graph-charges {
          background-color: rgba(239, 111, 108, 0.4);
        }
      }
      .horizontal-bar.graph-epargne-salariale {
        .graph-env-ind {
          background-color: rgba(104, 178, 187, 0.6);
        }
        .graph-charges {
          background-color: rgba(104, 178, 187, 0.4);
        }
      }
    }

    .graph-pie {
      text-align: center;
      position: relative;

      .graph-titreTypePrime {
        text-align: center;
        margin-top: rem-calc(11);
        margin-bottom: rem-calc(14);
      }

      .graph-economie {
        margin-top: rem-calc(35);
      }

      .graph-pie-prime-classique {
        transform: rotate(-70deg);
      }

      .graph-pie-es {
        transform: rotate(-120deg);
      }

      .graph-pie-prime-classique,
      .graph-pie-es,
      .graph-pie-trait {
        display: inline-block;
      }

      .graph-pie-item {
        .yes-indication {
          position: relative;
        }

        span {
          position: absolute;
          white-space: nowrap;
        }
      }

      .graph-pie-item {
        span.graph-charges-text {
          left: rem-calc(53);
          top: rem-calc(18);
          z-index: 1;
        }
      }

      .graph-pie-item:last-child {
        span.graph-somme-text {
          bottom: rem-calc(21);
          right: rem-calc(11);
        }
      }

      .graph-pie-trait {
        left: 50%;
        width: 1px;
        background: rgba(66, 86, 104, 0.1);
        height: rem-calc(154);
        position: absolute;
        top: rem-calc(109);
      }
    }

    .somme-economie {
      font-size: rem-calc(20);
    }
  }

  .details-restitution {
    .titreGraph {
      text-align: left;
    }

    .yes-titre-rectangle {
      position: relative;
      // padding-left: rem-calc(3px);
      // right: 20px;
    }

    .enDetails {
      right: 6px;
      bottom: -1px;
    }

    .yes-table {
      tr td:first-child,
      th {
        text-transform: uppercase;
      }

      tbody tr:last-child {
        background: $yes-primary-color;

        td {
          color: white;
        }
        td.yes-gras {
          font-weight: 600;
        }
      }
    }

    .detail-explication {
      margin-top: rem-calc(25);
    }

    .detail-explication-indication {
      font-size: rem-calc(14);
    }

    .detail-explication-enum {
      padding-left: rem-calc(20);

      span {
        display: block;
      }
    }
  }

  .yes-titre-rectangle {
    margin-top: rem-calc(18);
    margin-bottom: rem-calc(20);
    padding-left: rem-calc(8);
  }

  .titreGraph {
    font-family: $font-text;
    font-size: rem-calc(18);
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.7px;
    color: $yes-primary-color;
    text-align: center;
    text-transform: uppercase;
  }

  .sousTitreGraph {
    font-family: $font-text;
    font-size: rem-calc(14);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $yes-primary-color;
  }

  .enDetails {
    text-align: right;
    position: absolute;
    font-family: $font-text;
    font-size: rem-calc(16);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: 0.3px;
    color: #425668;
    cursor: pointer;
    width: auto;

    img {
      width: rem-calc(20);
      margin-left: rem-calc(8);
      position: relative;
      top: -1px;
    }
  }

  .grid-charges {
    position: relative;
  }

  .economies-rectangle {
    height: 100%;
    min-height: 100%;
  }

  .btnOuvrirPanelCharges {
    position: absolute;
    text-align: center;
    top: rem-calc(-22);

    .libelleCharges {
      font-family: $font-text;
      font-size: rem-calc(16);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #728d9c;
      bottom: rem-calc(17);
      position: relative;
    }

    img {
      width: rem-calc(35);
      background-color: #d7e4e9;
      border-radius: 50px;
      padding: 8px;
      padding-bottom: 10px;
      padding-top: 10px;
      position: relative;
      bottom: rem-calc(16);
      box-shadow: 4px 4px 3px 0 rgba(0, 0, 0, 0.1);
      opacity: 0.8;
      cursor: pointer;
      z-index: 1;
    }
  }

  .btnOuvrirPanelChargesOuvert img {
    transform: rotate(180deg);
  }

  .panelCharges {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
    margin: 0 auto;
    margin-top: rem-calc(0);
    box-shadow: 4px 4px 3px 0 transparent;
    border: solid 1px transparent;

    .titreCharges {
      padding-top: rem-calc(20);

      h2 {
        font-size: rem-calc(24);
      }
    }

    .choixCharges {
      text-align: center;
      width: 100%;
      padding-bottom: rem-calc(22);
      padding-top: rem-calc(22);

      input {
        width: rem-calc(70);
        border-bottom-color: $yes-primary-color !important;
        text-align: center;
      }

      .chargeTx {
        display: inline-block;
        margin-left: 25px;
        margin-right: 25px;

        .divInput {
          margin-top: 0;
        }
      }

      label {
        font-family: $font-text;
        font-size: rem-calc(16);
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        color: $yes-color-text;
        padding-right: 11px;
        position: relative;
        top: auto;
        left: auto;
        text-transform: none;
      }

      .divDropdown {
        display: inline-block;
      }
    }
  }

  .panelCharges.panelChargeOuvert {
    max-height: rem-calc(200);
    transition: all 0.5s ease;
    width: 100%;
    box-shadow: 4px 4px 3px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: solid 1px #e6eaee;
    margin-top: rem-calc(33);
  }

  .resultat-email-simulation {
    .titre-mail-simulation {
      text-align: center;
      color: white;
      margin-bottom: rem-calc(40);
    }

    .formMailSimulation {
      width: 75%;
      margin: 0 auto;

      .cell.medium-9 .divInput,
      .divInput.errorInput {
        margin-top: 0;

        input.inputText {
          color: white !important;
          border-bottom-color: white !important;
        }
        label {
          color: white !important;
        }
      }

      .btnValideMailSimulation {
        text-align: right;

        .yes-button {
          // background: $yes-primary-color-2;
          text-align: center;
        }
      }

      .errorMsgInput {
        display: none;
      }

      .txtEmailConditions {
        margin-top: rem-calc(20);

        p {
          font-family: $font-text;
          font-size: rem-calc(14);
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #ffffff;

          a {
            color: white;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .resultat-global {
    margin-top: rem-calc(20);

    .yes-rectangle {
      background: white;
    }

    .content-resultat-global {
      padding-top: rem-calc(40);
      width: 80%;
      margin: 0 auto;
      font-size: rem-calc(40);
      color: $yes-primary-color;
    }

    .cout-global-resultat {
      margin-bottom: rem-calc(30);
    }

    .economie-resultat {
      margin-top: rem-calc(50);
      display: inline-block;
      margin-right: rem-calc(10);
    }

    .resultat-economie-global {
      display: inline-block;
    }

    .yes-text-medium-resultat {
      font-family: $font-text;
      font-size: rem-calc(19);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $yes-color-text;

      div {
        margin-top: 3px;
      }
    }

    .yes-text-medium-enum-resultat {
      padding-top: rem-calc(10);
      padding-left: rem-calc(25);
      padding-bottom: rem-calc(26);
      color: $yes-primary-color;
      font-weight: 600;
    }

    .img-resultat-simulateur img {
      width: rem-calc(195);
      position: relative;
      top: rem-calc(15);
      left: rem-calc(36);
    }
  }

  .conseiller-simulation {
    .titre-mail-simulation {
      text-align: left;
    }

    p,
    h2 {
      color: white;
    }

    .img-conseiller-simulateur {
      text-align: right;

      img {
        width: rem-calc(240);
        position: relative;
        top: rem-calc(25);
        right: rem-calc(25);
      }
    }

    button {
      margin-top: rem-calc(25);
    }
  }
}

@media screen and (max-width: 1550px) {
  .item-simulateur-container.effectif-container {
    padding-top: 10%;
  }
}

@media screen and (max-width: 870px) {
  .item-simulateur-container.effectif-container .buttonValiderInformation {
    margin-top: rem-calc(60);
  }
}

@media screen and (min-width: 480px) and (max-width: 980px) {
  .item-simulateur-container
    .graphiques-restitution
    .graph-bar
    .graph-titreTypePrime {
    padding: 0;
    margin-right: 0;
  }

  .item-simulateur-container.restitution-container
    .center-item-simu-container
    .cell.medium-4
    .curseurs-rectangle
    .rectangle-curseurs {
    .cell.medium-8.sliderDiv {
      width: 50%;
      margin-right: 5px;
      margin-left: 5px;
    }
    .cell.medium-4 {
      width: 39%;
      margin-left: 1px;
      margin-right: 5px;
    }
  }
  .item-simulateur-container
    .center-item-simu-container
    .curseurs
    .rectangle-enveloppe {
    .cell.medium-8 {
      width: 43%;
      margin-right: 5px;
      margin-top: 0;
      margin-bottom: 10px;
    }
    .cell.medium-4 {
      width: 37%;
      margin-left: 3px;
    }
  }
  .item-simulateur-container .graphiques-restitution .efficacite-entreprise {
    .cell.medium-7 {
      margin-left: 4px;
      margin-right: 4px;
    }
    .cell.medium-2.graph-montantGlobal {
      padding: 0;
      margin: 0;
      width: 20%;
      padding-top: 10px;
    }
  }
}

// CSS FOR MOBILE
@media screen and (max-width: 480px) {
  .simulateur-container {
    margin-top: rem-calc(15);
    //margin: rem-calc(15);

    .effectif-container,
    .participation-container,
    .objectif-container,
    .restitution-container {
      margin: 0 rem-calc(15);
    }

    .illu-calcul,
    .illu-objectif,
    .indication-maj-donnees,
    .illu-participation,
    .img-resultat-simulateur,
    .img-conseiller-simulateur {
      display: none;
    }

    .buttonContainer {
      text-align: center;
    }

    .yes-tooltip {
      display: none;
    }

    .divInput {
      margin-bottom: rem-calc(25);
    }

    .informationsForm {
      .errorMsgInput {
        position: relative;
        display: inline-block;
        margin-bottom: 10px;
      }
      .divInput {
        margin-bottom: 8px;
      }
    }

    .item-simulateur-container.effectif-container .buttonValiderInformation {
      margin-bottom: rem-calc(30);
      margin-top: rem-calc(20);
    }

    .item-simulateur-container .center-item-simu-container .formObjectifs {
      .choix-objectif {
        .radio-objectif {
          padding: rem-calc(15);
        }
        p {
          font-size: rem-calc(14);
        }
      }
    }

    .item-simulateur-container
      .center-item-simu-container
      .formParticipation
      .formule-participation-card
      .formule-participation {
      width: 100%;
    }

    .item-simulateur-container
      .center-item-simu-container
      .formParticipation
      .connaitreMontantParticipation
      .montantParticipation
      .divInputMontantParticipation {
      width: 70%;

      .divInput {
        margin: 0;
      }
    }

    .item-simulateur-container
      .center-item-simu-container
      .formParticipation
      .connaitreMontantParticipation
      .montantParticipation
      .yes-montant-participation-calcule {
      font-size: rem-calc(20);
    }

    .item-simulateur-container
      .graphiques-restitution
      .graph-bar
      .graph-titreTypePrime {
      padding-bottom: rem-calc(20);
    }

    .item-simulateur-container
      .graphiques-restitution
      .graph-bar
      .horizontal-bar.graph-primeClassique {
      margin-bottom: 0;
    }

    .item-simulateur-container .graphiques-restitution .graph-bar .titreGraph {
      margin: 0 auto;
    }

    .item-simulateur-container .divGraphEconomie {
      margin: 0 auto;
    }

    .item-simulateur-container h3.graph-economie {
      font-size: rem-calc(18);
      text-align: center;
    }

    .item-simulateur-container .graphiques-restitution .somme-economie {
      display: inline-block;
    }

    .item-simulateur-container
      .center-item-simu-container
      .curseurs
      .formCurseurs
      .medium-4 {
      text-align: center;
      padding-top: 0;

      .inputWithoutRedux {
        width: 80%;
      }

      .percentSalaire {
        right: 0;
        display: block;
        text-align: center;
      }

      .divInput {
        margin-bottom: 5px;
      }
    }

    .item-simulateur-container
      .center-item-simu-container
      .curseurs
      .formCurseurs
      .sliderDiv {
      padding-left: 0;
      padding-bottom: rem-calc(20);

      .rangeslider.rangeslider-horizontal {
        margin: 0 auto;
        width: 80%;
      }
    }

    .item-simulateur-container .graphiques-restitution .enDetails {
      display: none;
    }

    .item-simulateur-container .yes-titre-rectangle {
      text-align: center;
    }

    .item-simulateur-container
      .graphiques-restitution
      .graph-pie
      .graph-pie-trait {
      display: none;
    }

    .item-simulateur-container.restitution-container .cell.medium-8 {
      margin-top: 25px;
    }

    .item-simulateur-container.restitution-container .btnOuvrirPanelCharges {
      left: 45%;
    }

    .item-simulateur-container .panelCharges .choixCharges {
      padding-top: rem-calc(12);
      text-align: left;
    }

    .item-simulateur-container .panelCharges.panelChargeOuvert {
      max-height: rem-calc(240);
    }

    .item-simulateur-container .panelCharges .choixCharges .chargeTx {
      margin-bottom: rem-calc(8);
    }

    .simulateur-container
      .item-simulateur-container
      .graphiques-restitution
      .somme-economie {
      padding-left: 3px;
    }

    .content-resultat-global
      .cell.medium-7
      .yes-text-medium-resultat:first-child
      .yes-gras {
      display: block;
    }

    .item-simulateur-container .resultat-global .yes-text-medium-resultat {
      margin-top: 0;
    }

    .email-container {
      padding: rem-calc(20) rem-calc(20) 0;
      text-align: center;

      .titre-mail-simulation {
        text-align: center;
      }
    }

    .simulateur-container .email-container .titre-mail-simulation {
      text-align: center;
    }

    .item-simulateur-container
      .resultat-email-simulation
      .formMailSimulation
      .btnValideMailSimulation {
      text-align: center;
    }
  }
}
