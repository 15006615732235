h1 {
  font-size: rem-calc(45);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: normal;
}

.sous-titre {
  font-family: $font-text;
  font-size: rem-calc(23);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #425668;
}

.sous-titre-gris {
  font-family: $font-text;
  font-size: rem-calc(18);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: rem-calc(26);
  letter-spacing: normal;
  color: #89969f;
}

.titre-vert {
  font-size: rem-calc(26);
  font-family: 'Playfair Display', 'serif';
  color: $yes-primary-color;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: 0.5px;
}

h2 {
  font-size: rem-calc(32);
  line-height: 1.25;
  letter-spacing: 0.6px;
}

h3 {
  font-size: rem-calc(18);
  line-height: 1.5;
  letter-spacing: normal;
  color: $yes-color-text;
}

p {
  font-size: rem-calc(16);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin: 0;
  color: $yes-color-text;
}

a {
  color: $yes-primary-color;
}

a:hover {
  color: $yes-primary-color;
  text-decoration: underline;
}

.inlineBloc {
  display: inline-block;
}

sup {
  font-family: 'superscript';
  top: -0.25rem;
  font-size: 0.6rem;
}

.titreFormulaire {
  font-size: rem-calc(16);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-transform: uppercase;
  color: $yes-color-title;
  margin-top: rem-calc(32);
  display: inline-block;
  letter-spacing: 1px;
}

.yes-label {
  color: #425668;
}
.ges-circular-progress {
  color: $yes-button-primary-color;
}

.yes-button {
  width: auto;
  padding-left: rem-calc(32.5);
  padding-right: rem-calc(32.5);
  height: rem-calc(44);
  border-radius: rem-calc(4);
  background-color: $yes-button-primary-color;
  box-shadow: 4px 4px 3px 0 rgba(0, 0, 0, 0.1);
  font-size: rem-calc(16);
  text-align: center;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 1px;
  transition: all 0.1s ease-in-out;
  opacity: 0.8;
}

.white-button .yes-button {
  background: white;
  color: $yes-primary-color;
}

.yes-button:hover:enabled {
  background-color: $yes-primary-color-hover;
  opacity: 1;
  transition: all 0.1s ease-in-out;
}

.yes-button.yes-button-disabled:hover {
  background-color: $yes-primary-color;
}

.yes-rectangle {
  border-radius: 4px;
  box-shadow: 4px 4px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1px #e6eaee;
  padding: rem-calc(14);

  p {
    margin-bottom: 1rem;

    &.error-color {
      color: $yes-primary-color;
    }
  }
}

.yes-titre-rectangle {
  font-family: $font-text;
  font-size: rem-calc(24);
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #425668;
}

.yes-indication {
  font-family: $font-text;
  font-size: rem-calc(14);
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $yes-color-text;
}

.globalErrorForm {
  border: 1px solid #f1bfc9;
  background: #f4c7d0;
  color: #ed365a;
  border-radius: 4px;
  padding: 4px;
  font-family: $font-text;
  font-size: rem-calc(15);
  padding-left: 7px;
  margin-top: 9px;
  margin-bottom: 3px;
}

.yes-button:disabled,
.yes-button-disabled {
  opacity: 0.5;
  cursor: default;
}

.yes-gras {
  font-weight: 600;
}

.yes-gras-2 {
  font-weight: 600;
  font-size: rem-calc(18);
  font-family: $font-text;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.65;
  letter-spacing: normal;
  color: #425668;
}

.yes-green {
  color: $yes-entreprise-card-part2-color;
}

.margin-b-1 {
  margin-bottom: 1rem;
}

.yes-sup-infos {
  font-size: .7rem;
  font-style: italic;
}

.yes-vertical-top {
  vertical-align: top;
}

.white {
  color: white;
}

.background-green {
  background: $yes-primary-color-3;
}

.background-bleugris {
  background: rgba(55, 104, 129, 0.5);
}

.background-bleugris:hover,
button.background-bleugris:hover:enabled {
  background: rgba(55, 104, 129, 0.7);
}

button.background-bleugris.yes-button-disabled:hover {
  background: rgba(55, 104, 129, 0.5);
}

// .background-bleugris, .yes-button-disabled{
//   background: rgba(55, 104, 129, 0.5);
// }

.background-white {
  background: white;
}

table.yes-table {
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: left;
    padding-bottom: rem-calc(6);
    font-family: $font-text;
    font-size: rem-calc(14);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.5px;
    color: #425668;
  }

  tbody {
    background: white;
    border: 1px solid #e6eaee;
    box-shadow: 4px 4px 3px 0 rgba(0, 0, 0, 0.1);

    tr:nth-child(2n) {
      background: rgba(230, 234, 238, 0.5);
    }

    td {
      padding: 10px;
      font-family: $font-text;
      font-size: rem-calc(16);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 0.6px;
      color: #425668;
    }
  }
}

.layoutDisable {
  opacity: 0.3;
  pointer-events: none;
}

// SLIDER
.rangeslider.rangeslider-horizontal {
  box-shadow: none;
  background: $yes-slider-range-color;
  margin: 0;
  height: 8px;

  .rangeslider__fill {
    background-color: $yes-slider-trace-color;
    box-shadow: none;
  }

  .rangeslider__handle {
    background: $yes-slider-cursor-border-color;
    box-shadow: none;
    border: 1px solid #d7e4e9;
    width: 24px;
    height: 24px;
  }

  .rangeslider__handle::after {
    background: $yes-slider-cursor-fill;
    box-shadow: none;
    width: 10px;
    height: 10px;
    top: 6px;
    left: 6px;
  }
}

.infoMaxSlider {
  font-family: $font-text;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $yes-color-text;
  font-style: italic;
}

// PIE
svg {
  display: inline-block;
  vertical-align: middle;
  transform-origin: 50% 50%;
  // animation: scale .6s;
  margin: 10px;

  text {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bolder;
    font-size: 12px;
  }
}

// CONTACT
.contact-container {
  position: fixed;
  right: rem-calc(10);
  bottom: rem-calc(35);

  .num-tel-contact {
    border-radius: 4px;
    background-color: rgba(247, 251, 251, 0.7);
    box-shadow: 4px 4px 3px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    display: inline-block;
    padding: rem-calc(10);
    right: rem-calc(25);
    bottom: rem-calc(2);
    font-family: $font-text;
    font-size: rem-calc(18);
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $yes-primary-color;

    .triangle-contact {
      width: rem-calc(10);
      height: rem-calc(10);
      position: absolute;
      right: rem-calc(-5);
      bottom: rem-calc(-2);
      transform: translateY(-15px) rotate(45deg);
      box-shadow: 3px -3px 3px -1px rgba(0, 0, 0, 0.1);
      background-color: rgba(247, 251, 251, 0.7);
    }
  }

  .yes-contact {
    width: rem-calc(55);
    height: auto;
    cursor: pointer;
  }
}

// TELEPHONE
.infosContactTunnel:hover {
  opacity: 1;
}

.infosContactTunnel {
  opacity: 0.8;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #bf252b;
  a {
    text-decoration: none;
  }

  img {
    width: rem-calc(14);
    height: rem-calc(14);
    position: relative;
    bottom: 1px;
  }

  span {
    padding-left: 6px;
    font-size: rem-calc(18);
    color: #bf252b;
  }

  .logoRetourTunnel {
    position: relative;
    bottom: rem-calc(3);
    left: rem-calc(7);
    cursor: pointer;
  }
}

// NOTIFICATION
.notification-container {
  .notification {
    background-color: $yes-primary-color;
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    color: $yes-color-text;
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    top: 30px;
  }

  .notification.show {
    color: white;
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 3.5s;
    animation: fadein 0.5s, fadeout 0.5s 3.5s;
  }
}

// Dropzone
.uploadZone {
  margin-bottom: rem-calc(20);
  margin-top: rem-calc(10);
  margin-left: rem-calc(30);

  .uploadError {
    font-size: rem-calc(14);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #ed365a;
    font-family: $font-text;
    width: 100%;
    margin-bottom: rem-calc(6);
  }

  .yesDropzone {
    padding: 0;
    background: $yes-light-green-transparent;

    div:first-child {
      width: 100% !important;
      border: none !important;
      cursor: pointer;
      height: auto !important;
      padding: rem-calc(20) !important;
    }

    .imgUpload {
      height: rem-calc(35);
    }

    p {
      font-size: rem-calc(16);
      color: $yes-primary-color;
      display: inline-block;
      margin-left: rem-calc(18);
    }
  }

  .listeDocuments {
    margin-top: rem-calc(25);

    .yes-cross {
      width: rem-calc(9);
      position: relative;
      bottom: 12px;
      margin-right: 20px;
      left: 4px;
      cursor: pointer;
    }

    .uploadEnCours {
      text-align: center;
      color: $yes-secondary-color;
      font-size: rem-calc(14);
      position: relative;
      bottom: rem-calc(6);
    }

    .document {
      display: inline-block;
      font-family: $font-text;
      font-size: rem-calc(16);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 0.3px;
      color: #425668;
      padding-bottom: rem-calc(10);
    }
  }
}

.erreur404 {
  width: 80%;
  margin: 0 auto;
  padding-top: 35px;
  height: 100%;

  .logoYomoni {
    width: 173px;
    height: 50px;
  }

  .content404 {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 85px);

    h1 {
      margin-top: rem-calc(10);
    }

    p {
      color: #425668;
      width: 50%;
      text-align: center;
      margin: 0 auto;
      margin-top: rem-calc(15);
      margin-bottom: rem-calc(20);
    }

    .illuPageIntrouvable {
      width: 35%;
      margin: 0 auto;
      margin-bottom: rem-calc(40);
    }

    .telephone404 {
      color: inherit;
      text-decoration: underline;
    }
  }
}

/* Animations to fade the notification in and out */
@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@media screen and (max-width: 1550px) {
  p {
    font-size: rem-calc(18);
  }
}
@media screen and (max-width: 1550px) and (max-height: 690px) {
  html {
    font-size: 15px;
  }
}

// CSS FOR MOBILE
@media screen and (max-width: 480px) {
  .App-intro {
    font-size: 16px;
    background: white;
  }

  h1 {
    font-size: rem-calc(26);
  }

  h2 {
    font-size: rem-calc(22);
    line-height: 1.36;
    letter-spacing: 0.4px;
  }

  h3 {
    font-size: rem-calc(16);
    font-weight: 600;
    letter-spacing: normal;
  }

  p {
    font-size: rem-calc(16);
    line-height: 1.38;
    letter-spacing: normal;
  }

  .titreFormulaire {
    font-size: rem-calc(14);
    line-height: 1.71;
    letter-spacing: 0.5px;
    margin-top: 2px;
  }

  .sous-titre {
    font-size: rem-calc(16);
  }

  .yes-button {
    width: 100%;
    padding-left: rem-calc(5);
    padding-right: rem-calc(5);
  }

  // Chat desactivé sur mobile
  .zopim {
    display: none !important;
  }

  .erreur404 {
    padding-top: 15px;

    .divLogoMobile404 {
      display: block;
      text-align: center;

      .logoYomoniMobile {
        width: 10%;
      }
    }

    .content404 {
      p {
        width: 100%;
      }

      .illuPageIntrouvable {
        margin-bottom: 0;
        margin-top: rem-calc(20);
        width: 70%;
      }
    }
  }
}
