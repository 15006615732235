.work_advices_sheets-ul--contrasted-color {
  --contrasted-color: #4f5051;
  color: var(--contrasted-color);
}

.work_advices_sheets-ul--liststyle-none {
  list-style: none;
}

.work_advices_sheets-ul--li-liststyle-contrasted-color::before {
  content: "•";
  color: var(--contrasted-color);
  position: absolute;
  display: inline-block;
  width: 1em;
  margin-left: -1em;


}