html,
body,
#root,
.App-tunnel {
  height: 100%;
}

body {
  background: $yes-primary-color-4;
}

// ECRAN NO MOBILE
.noMobileScreen {
  background: $yes-primary-color;
  height: 100%;
  padding-left: rem-calc(23);
  padding-right: rem-calc(23);
  min-height: 100%;
  height: auto;

  .blason-no-mobile {
    text-align: center;
    padding-top: 17px;
  }

  .illu-no-mobile {
    text-align: center;
    margin-top: rem-calc(45);
  }

  .txt-connexion-desktop {
    font-size: rem-calc(18);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: -0.1px;
    text-align: center;
    color: white;
    margin-top: rem-calc(15);
    flex: 1;
  }

  .white-button {
    padding-bottom: rem-calc(44);
    margin-top: rem-calc(44);

    .yes-button:hover {
      background-color: white;
    }
  }

  .txt-felicitation {
    font-family: 'Playfair Display', 'serif';
    font-size: rem-calc(23);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: center;
    color: white;
    margin-top: rem-calc(63);
  }
}

// Disable layout tunnel
.App-tunnel.hideLayoutTunnel {
  .cell-content .content-dossier {
    padding: 0;
    width: 100%;
  }

  .cell.medium-4,
  .frise-unknown-enabled,
  .menu-mobile-tunnel {
    display: none;
  }
}

.App-tunnel {
  max-width: 100%;
  margin: 0 auto;
  min-height: 100%;
  height: auto;

  .errorMsgInput {
    max-width: 100%;
  }

  .divDropdown {
    margin-top: rem-calc(38);

    .dropdown {
      width: 100%;
      // border-bottom: 1px solid #728d9c;
      background-position-x: 97%;
    }

    .dropdown:focus {
      width: 100%;
      border-bottom: 1px solid $yes-primary-color;
    }
  }

  .cell-aide {
    background: $yes-primary-color-4;
    position: relative;
    display: inline-block;
    vertical-align: top;

    .aide-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100vh;

      .lienLogoTunnel {
        pointer-events: none;
      }

      .lienLogoTunnel.activerLienLogoTunnel {
        pointer-events: initial;
      }

      .yes-logo-dossier {
        flex: 0;
        text-align: center;
        padding: rem-calc(25);

        .logoDossierYomoni {
          width: 40%;
        }
      }

      .aide-info {
        margin-top: rem-calc(68);
        padding-left: 13%;
        padding-right: 13%;
        flex: 1;
        background: $yes-primary-color-4;
        .infos-etape-menu {
          padding-top: rem-calc(64);
          padding-left: rem-calc(16);
          padding-right: rem-calc(16);

          .etape-info-item-menu {
            padding-bottom: rem-calc(24);
            cursor: pointer;

            .etape-info-fleche {
              float: right;
              position: relative;
              top: 4px;
            }

            span {
              padding-left: rem-calc(16);
              font-size: rem-calc(18);
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.5;
              letter-spacing: 0.6px;
              color: $yes-info-etape-menu;
              text-transform: uppercase;
            }
          }
        }

        .infos-etape-menu {
          .etape-info-item-menu {
            opacity: 0.4;
            transition: 0.3s;
          }
          .etape-info-item-menu:hover {
            opacity: 1;
          }
        }

        .infos-etape-menu.actif-1 {
          .etape-info-item-menu:first-child {
            opacity: 1;
          }
        }

        .infos-etape-menu.actif-2 {
          .etape-info-item-menu:nth-child(2) {
            opacity: 1;
          }
        }

        .infos-etape-menu.actif-3 {
          .etape-info-item-menu:last-child {
            opacity: 1;
          }
        }

        .info-dossier {
          .titre-info {
            font-size: rem-calc(26);
            font-family: 'Playfair Display', 'serif';
            color: $yes-primary-color;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.54;
            letter-spacing: 0.5px;
            display: inline-block;
            margin-bottom: rem-calc(3);
            margin-top: rem-calc(3);
          }
          a {
            text-decoration: underline;
          }
          .margeInfo {
            margin-bottom: rem-calc(20);
            .infoLink {
              text-decoration: underline;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        .text-info-last-etape {
          margin-top: rem-calc(6);
        }
      }

      .retourAndTelephone {
        margin-top: 0.25rem;
        margin-bottom: rem-calc(25);
        padding-left: 13%;
        padding-right: 13%;
        width: 100%;
        background: $yes-primary-color-4;

        .itemTelRetour:first-child {
          cursor: pointer;
          color: $yes-primary-color;
        }

        .itemTelRetour:last-child {
          float: right;
        }

        .itemTelRetour {
          display: inline-block;
          opacity: 0.8;
          &:first-of-type {
            background-color: $yes-retour-button-color;
            border-radius: 5px;
            padding: 0.5rem;
            margin-bottom: 1rem;
          }
          .logoRetourTunnel {
            position: initial;
            padding-left: 0.5rem;
            vertical-align: baseline;
          }
          .libelleRetour {
            color: white;
          }
        }

        .itemTelRetour:hover {
          opacity: 1;
        }

        .libelleRetour {
          font-family: $font-text;
          font-size: rem-calc(18);
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: right;
          color: $yes-libelle-retour-color;
          cursor: pointer;
        }

        .logoRetourTunnel {
          position: relative;
          bottom: rem-calc(3);
          left: rem-calc(7);
          cursor: pointer;
        }
      }
    }
  }

  .cell-content {
    height: auto;
    background: white;
    overflow: auto;
    display: inline-block;
    vertical-align: top;

    .frise-dossier {
      box-shadow: 0px 2px 0px rgba(66, 86, 104, 0.2);
      padding: rem-calc(35);
      position: relative;

      .frise-item {
        display: inline-block;
        opacity: 0.5;

        img {
          width: rem-calc(26);
        }

        .frise-txt {
          font-family: $font-text;
          font-size: rem-calc(16);
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $yes-primary-color;
          padding-left: rem-calc(10);
          position: relative;
          top: 1px;
        }
      }

      .frise-votreBesoin {
        margin-left: rem-calc(25);
        margin-right: rem-calc(25px);
      }

      .yes-right-part {
        position: absolute;
        right: rem-calc(20);

        .yes-info {
          font-family: $font-text;
          font-size: rem-calc(16);
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $yes-secondary-color;
          padding-right: rem-calc(20);
          position: relative;
          max-width: 300px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          top: 6px;
        }

        img {
          cursor: pointer;
        }
      }

      .hidden-frise {
        visibility: hidden;
      }
    }

    .frise-dossier.frise-entreprise-enabled {
      .icon-etape-une-done,
      .icon-etape-deux-done {
        display: none;
      }

      .frise-votreEntreprise {
        opacity: 1;
      }
    }

    .frise-dossier.frise-besoin-enabled {
      .frise-votreEntreprise {
        opacity: 1;
      }
      .icon-etape-une {
        display: none;
      }
      .icon-etape-deux-done {
        display: none;
      }

      .icon-etape-une-done {
        display: inline-block;
      }

      .frise-votreBesoin {
        opacity: 1;
      }
    }

    .frise-dossier.frise-adhesion-enabled {
      .icon-etape-une,
      .icon-etape-deux {
        display: none;
      }

      .frise-votreAdhesion,
      .frise-votreBesoin,
      .frise-votreEntreprise {
        opacity: 1;
      }
    }

    .content-dossier {
      padding: rem-calc(43);
      padding-right: 15%;
      text-align: left;
      overflow: auto;
      // min-height: 100%;

      .titreFormulaire {
        margin-top: rem-calc(15);
      }

      .buttonCreationContainer {
        margin-top: rem-calc(50);
      }

      .formTunnel {
        .tunnelRadios {
          .tunnelRadio {
            margin-top: rem-calc(20);
            display: inline-block;
          }
          .tunnelRadio:last-child {
            margin-left: 10%;
          }
        }

        h3 {
          margin-top: rem-calc(26);
        }
      }

      .separateur {
        margin-bottom: rem-calc(30);
      }

      // Champ code NAF du formulaire d'entreprise
      .codeNaf .inputText {
        text-transform: uppercase;
      }

      .formeJuridique .divDropdown .labelDropDown {
        color: $yes-primary-color;
      }

      .top-separator {
        margin-top: 2.5rem;
      }

      .checkedInput {
        .divCheckbox {
          margin-bottom: 1.5rem;
        }
      }

      .formCountries .divDropdown {
        margin-top: 0;
      }

      .formCountries .divDropdown .labelDropDown {
        color: $yes-primary-color;
      }

      // FORMULAIRE INFO COMPLEMENTAIRE
      .formEntrepriseInfosComplementaires {
        .divInput {
          margin-top: rem-calc(12);

          .inputText {
            width: 18%;
          }
        }
      }

      // FORMULAIRE DISPOSITIF ACTUEL
      .formDispositifActuel {
        .tunnelRadios {
          // margin-top: rem-calc(20);

          .tunnelRadio:last-child {
            margin-left: 10%;
          }
        }
      }

      // FORMULAIRE DISPOSITIF ABONDEMENT
      .formDispositifAbondement {
        .titreFormulaire {
          margin-bottom: rem-calc(26);
        }

        .boutonAjouterPalier {
          color: $yes-primary-color;
          font-size: rem-calc(14);
          margin-left: rem-calc(30);
          text-transform: uppercase;
          cursor: pointer;

          .ajoutPalierLogo {
            margin-right: rem-calc(8);
            margin-bottom: rem-calc(3);
          }
        }

        .boutonSupprimerPalier {
          color: $yes-primary-color;
          font-size: rem-calc(14);
          text-transform: uppercase;
          cursor: pointer;
          margin-left: rem-calc(10);
          .suppressionPalierLogo {
            margin-bottom: rem-calc(3);
          }
        }

        .errorMsgTauxDegressif {
          padding-top: 0.25rem;
          color: #c21b17;
          font-size: rem-calc(14);
        }

        .boutonAjouterPalierADroite {
          align-self: flex-end;
        }

        .alignContentInRow {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .displayContents {
          display: contents;
        }

        .divInput {
          margin-top: 0;
        }

        .parametrageAbondement {
          margin-top: rem-calc(7);
          margin-bottom: rem-calc(7);
          height: rem-calc(27);
          font-size: rem-calc(14);
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.25;
          text-transform: uppercase;
          letter-spacing: rem-calc(1);
          color: #3b3c3d;
        }
        .selectionTaux {
          .divDropdown {
            background-color: #f8f9fb;
          }
          .palier {
            background-color: #fff;
            padding: rem-calc(12) rem-calc(8);
            margin-bottom: rem-calc(10);
            width: 97%;
          }
        }

        .alignContentInColumn {
          display: flex;
          flex-direction: column;
        }
        .alignPeiPercoiColumn {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .label-checkbox-v2 {
          color: $yes-label;

          &.anciennete-mini-selector {
            padding-left: 0;
            text-align: justify;
          }
        }

        .anciennete-mini-selector {
          margin-top: 1rem;
        }

        .periodicite {
          .divDropdown {
            margin-top: 0;
          }
        }

        .yes-rectangle {
          margin-bottom: rem-calc(15px);
          background: $yes-primary-color-4;
          padding: rem-calc(20);

          .disablePartieTunnel {
            opacity: 0.4;
            // pointer-events: none;
            position: relative;
          }

          .disablePartieTunnel:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
          }

          .contentAbondementActive {
            .tauxEtPlafondAbondement {
              padding-right: rem-calc(35);
            }

            padding: rem-calc(27);

            .yes-label {
              margin-right: rem-calc(8);
              margin-left: rem-calc(10);
              color: $yes-label;
              font-size: rem-calc(14);
            }

            .disableSlider {
              .rangeslider__fill {
                background-color: #a6a8ab;
              }
              .rangeslider-horizontal {
                background: #d7d9dc;
              }
              .rangeslider__handle {
                background: #ffffff;
                cursor: unset;
              }
              .rangeslider__handle::after {
                background: #a6a8ab;
              }
            }

            .sliderDispositifAbondement {
              width: rem-calc(135);
              position: relative;
              left: rem-calc(15);
            }

            .divDropdown {
              margin-top: 0px;
              width: rem-calc(100);
            }

            .checkboxMiseEnPlaceAbondement {
              margin-bottom: rem-calc(20);

              .divCheckbox {
                display: inline-block;
              }

              .label-checkbox-v2 {
                bottom: 2px;
                right: 5px;
                cursor: pointer;
                color: $yes-label;
              }
            }

            .plafondAbondementInput {
              margin: 0 rem-calc(30) 0 auto;
              width: 35%;
              @media screen and (max-width: 1440px) {
                width: 30%;
              }
              .inputEnabled .divInput .inputText {
                background: $yes-input-background-enabled;
              }
              .inputDisabled .divInput .inputText {
                background: $yes-input-background-color;
              }
            }

            .sourcesVersementAbondees {
              align-self: center;
              margin-right: rem-calc(10);
              .noChecked {
                display: flex;
                align-items: center;
                padding-right: rem-calc(15);
                padding-left: rem-calc(15);
                background-color: #ffffff;
                border-radius: rem-calc(4);
                height: rem-calc(40);
                cursor: pointer;
                .yes-checkbox {
                  display: none;
                }

                .label-checkbox-v2 {
                  color: $yes-label;
                  bottom: rem-calc(2);
                }
              }
              .checked {
                display: flex;
                align-items: center;
                padding-right: rem-calc(15);
                padding-left: rem-calc(15);
                cursor: pointer;
                height: rem-calc(40);
                background-color: $yes-primary-color;
                border-radius: rem-calc(4);

                .yes-checkbox {
                  bottom: rem-calc(2);
                }

                .label-checkbox-v2 {
                  bottom: rem-calc(2);
                  color: #ffffff;
                }
              }
              &.disabled {
                opacity: 0.5;
                .noChecked,
                .yes-checkbox,
                .label-checkbox-v2 {
                  cursor: default;
                }
              }
            }

            .premierNiveauAbondement .label-checkbox-v2 {
              font-family: $font-text;
              font-size: rem-calc(18);
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.33;
              letter-spacing: normal;
              color: #425668;
            }
            select,
            input {
              background-color: #f8f9fb;
              width: rem-calc(100);
            }
          }
          .infosAbondementSources,
          .infosAbondementPass {
            font-family: $font-text;
            font-size: rem-calc(12);
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $yes-color-text;
          }

          .infosAbondementPass {
            font-style: italic;
            bottom: rem-calc(21);
            right: 0;
            margin-left: rem-calc(21);
          }

          .infosMaxPalier {
            font-family: $font-text;
            font-size: rem-calc(12);
            font-weight: 300;
            line-height: 1.5;
            color: $yes-color-text;
            font-style: italic;
            bottom: rem-calc(21);
            margin-left: rem-calc(21);
            @media screen and (max-width: 1440px) {
              width: 13%;
            }
          }
        }
        .tunnelRadios {
          display: flex;
        }

        .abondement-unilateral {
          margin-bottom: 2rem;
          color: #3b3c3d;
        }

        .slider-abn-unilateral {
          position: relative;
          color: #8da1bb;
        }

        .errorMsg {
          color: #ed365a;
          font-size: 0.8rem;
        }

        .warningMsg {
          padding-top: 0.25rem;
          color: #e95730;
          font-size: 0.8rem;
        }
      }

      // FORM ABONDEMENT UNILATERAL
      .formAbondementUnilateral {
        .yes-rectangle {
          background: $yes-primary-color-4;
          width: 65%;
          padding: rem-calc(25);
          display: flex;
          align-items: center;
          margin-top: rem-calc(20);

          .slider-abn-unilateral {
            position: relative;

            .infoMaxSlider {
              position: absolute;
              right: rem-calc(-12);
              bottom: rem-calc(15);
            }
          }

          .champInputAbnUnilateral .inputEnabled {
            margin-left: rem-calc(60);
          }

          .divInput {
            margin-top: 0;

            input {
              background: white;
            }
          }
        }

        .msgInfoAbondementUnilateralDansPercoi {
          color: $yes-primary-color;
        }
      }

      // FORM TARIFICATION
      .formTarification {
        .titreFormulaireGes {
          margin-bottom: rem-calc(25);
          color: #4f5051;
          text-transform: uppercase;
        }

        .inputIban {
          width: 45%;
        }

        .inputBic {
          width: 25%;
        }

        .input-tooltip {
          background-color: #ffffff;
          color: #6f7072;
          font-style: normal;
        }

        .titreTarificationIcone {
          margin-bottom: rem-calc(25);
        }

        .titreTarification {
          margin-top: rem-calc(60);
        }

        .titreTarificationGes {
          margin-top: rem-calc(24);
          margin-bottom: rem-calc(6);
          color: #6f7072;
          letter-spacing: 1px;
          line-height: 24px;
        }

        .yes-info-tarif {
          color: #425668;
          display: flex;
          justify-content: flex-end;
          height: 100%;
          flex-direction: column;
        }

        .ges-tarification-inline-display {
          display: flex;
          flex-direction: row;
          align-items: baseline;

          .text-info-last-etape {
            font-size: rem-calc(16);
          }

          .label-checkbox-v2 {
            color: #6f7072;
            bottom: 2px;
          }

          .tarification-frais-conseils {
            margin-left: rem-calc(16);
            bottom: 25px;
          }

          .tarification-input {
            width: 15%;
            .inputEnabled .divInput {
              width: rem-calc(80);
            }

            .errorMsgInput {
              left: 6rem;
              top: -0.5rem;
            }
          }
        }

        .ges-tarification-label {
          flex: 1;
          padding-left: 2rem;
        }

        .ges-tarification-inline-display > .label-checkbox-v2 {
          font-size: rem-calc(16);
        }

        .ges-libelle-tarification {
          font-size: rem-calc(18);
          font-family: $font-text;
          color: #6f7072;
          margin-right: rem-calc(15);
        }

        .ges-tarification-rectangle {
          display: flex;
          height: 60px;
          flex-direction: row;
          background: rgba(111, 112, 114, 0.03);
          align-items: center;

          .logo-container-tarification {
            margin-left: rem-calc(10);
          }

          .logoUsersSmall {
            width: rem-calc(25);
            margin-bottom: rem-calc(5);
          }

          .txt-tarif-rectangle {
            font-size: rem-calc(14);
            color: #6f7072;
            margin-right: 14px;
          }

          .prix-tarif-rectangle {
            font-size: rem-calc(20);
            color: $yes-primary-color;
            margin: 10px;
          }
        }

        .repartition-block {
          &:first-of-type {
            margin-right: 2rem;
          }

          span:first-of-type {
            margin-right: 1rem;
          }
          span:last-of-type {
            margin-left: 1rem;
          }
        }

        .fees-distrib-option {
          margin: 1rem auto;
        }

        .radio-container-tarification-ges {
          display: flex;
          align-items: baseline;
          margin-left: rem-calc(50);
          .yes-circle {
            top: 5px;
          }
          .labelChoix {
            color: #6f7072;
            bottom: 0;
          }
          .tunnelRadio {
            .alignRadio {
              display: flex;
              align-items: center;
            }
          }
        }

        .tarification-input {
          .divInput {
            margin-top: 0;
          }
        }
        .frais-dossier-input {
          .divInput {
            width: rem-calc(100);
          }
        }

        .separateur-forfaits {
          margin: 12px;
          color: rgba(111, 112, 114, 0.5);
        }

        .yes-rectangle {
          text-align: center;
          background: $yes-primary-color-4;

          .logo-container-tarification {
            height: 40px;

            .logoEntrepriseSmall {
              width: rem-calc(20);
              vertical-align: bottom;
              position: relative;
              bottom: rem-calc(-17);
            }

            .logoEntrepriseMedium {
              width: rem-calc(40);
            }
          }

          .txt-tarif-rectangle {
            font-size: rem-calc(16);
            color: #728d9c;
            margin-top: 2px;
          }

          .prix-tarif-rectangle {
            font-size: rem-calc(25);
            color: $yes-color-text;
            font-weight: bold;
          }
        }
      }

      // Interessement
      .formInteressement {
        .messageAccompagnement {
          background-color: rgba(111, 112, 114, 0.03);
          color: #6f7072;
          font-size: rem-calc(16);
          line-height: rem-calc(24);
          border-radius: 4px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 20px;
          margin-top: 30px;
        }
      }

      // Participation
      .formParticipation {
        .divDropdown {
          margin-top: 0px;
        }

        .infoCloture {
          margin-bottom: rem-calc(15);
        }

        .dureeParticipation {
          padding-left: rem-calc(16);
          padding-top: rem-calc(7);
        }

        .modeRepartitionChoix {
          margin-top: rem-calc(25);
          margin-bottom: rem-calc(15);
        }

        .repartition-text {
          padding-left: 0.25rem;
        }

        .messageAccompagnement {
          background-color: rgba(111, 112, 114, 0.03);
          color: #6f7072;
          font-size: rem-calc(16);
          line-height: rem-calc(24);
          border-radius: 4px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 20px;
          margin-top: 30px;
        }

        .tunnelRadios {
          .tunnelRadio:not(:first-child) {
            margin-left: 10%;
          }
        }

        .errorMsg {
          color: #ed365a;
          font-size: 0.8rem;
        }
      }

      .formFormuleParticipation {
        h3 {
          margin-bottom: rem-calc(15);
        }

        .divFormuleDerogatoire {
          margin-top: rem-calc(20);
        }

        .dropdownFormules .divDropdown {
          margin-top: rem-calc(3);
        }

        .labelRadioFormule {
          font-family: $font-text;
          font-style: italic;
          font-size: rem-calc(15);
          font-weight: 300;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #89969f;
          position: relative;
          bottom: rem-calc(6);
          right: rem-calc(8);
        }

        .radioFormule .labelChoix {
          color: #425668;
          font-weight: 600;
        }

        .formuleLegale {
          font-family: $font-text;
          font-size: rem-calc(17);
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.83;
          letter-spacing: normal;
          color: #89969f;
        }

        .dropdownFormules {
          width: 80%;
        }
      }

      .formMoyensPaiement {
        .fields {
          margin-left: 1rem;
        }
      }

      // ECRAN Informations
      .tunnel-infos-container-child {
        width: 100%;

        p {
          font-size: rem-calc(18);
        }

        a {
          font-size: rem-calc(16);
        }

        .choix-documents {
          margin-bottom: rem-calc(12);
          display: table;
          width: 100%;

          .document-identite-text {
            font-family: $font-text;
            font-size: rem-calc(18);
            font-style: normal;
            font-weight: bold;
            font-stretch: normal;
            line-height: 1.83;
            letter-spacing: normal;
            color: $yes-color-text;
            position: relative;
            bottom: rem-calc(5);
            left: rem-calc(10);
            display: flex;
          }

          .radio-identity {
            margin-top: 5px;
            margin-left: 30px;
          }

          .radio-document,
          .text-document {
            display: table-cell;
          }

          .text-passeport {
            margin-right: rem-calc(40);
          }

          .radio-document {
            vertical-align: middle;
          }

          label.text-document {
            cursor: pointer;
          }

          label.text-document .text-document-titre {
            font-family: $font-text;
            font-size: rem-calc(18);
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.83;
            letter-spacing: normal;
            color: $yes-color-text;
            position: relative;
            bottom: rem-calc(5);
            left: rem-calc(10);
          }
        }

        .text-document-identite {
          font-family: $font-text;
          font-size: rem-calc(18);
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.83;
          letter-spacing: normal;
          color: $yes-color-text;
          position: relative;
          bottom: rem-calc(5);
          left: rem-calc(10);
          margin-right: 60px;
        }

        .yesInfoDocumentsTransmis {
          margin-top: rem-calc(25);
        }

        .yesDropzone p {
          font-size: rem-calc(16);
          color: $yes-primary-color;
        }

        .etape-apres-signature {
          position: relative;

          .yes-circle-check {
            background-color: $yes-circle-check-color;
            width: rem-calc(10);
            height: rem-calc(10);
            display: inline-block;
            border-radius: rem-calc(10);
            position: absolute;
            left: rem-calc(-29);
            top: 50%;
            margin-top: -3px;
          }

          .yes-circle-check-ok {
            background: $yes-primary-color;
          }

          .yes-circle-check-ok {
            background: $yes-primary-color;
          }

          .icon-check-ok {
            position: relative;
            bottom: rem-calc(10);
            width: 0.33333rem;
            left: rem-calc(2);
          }

          .content {
            span {
              font-size: rem-calc(15);

              a {
                font-size: rem-calc(15);
              }
            }
          }

          .link:hover {
            text-decoration: underline;
            cursor: pointer;
          }

          p {
            margin-left: rem-calc(25);
            position: relative;
          }
        }

        .etape-apres-signature:before {
          position: absolute;
          border: 1px dashed $yes-primary-color;
          height: calc(100% - 2.6rem);
          content: '';
          top: rem-calc(20);
        }

        .pieces-justificatives {
          margin-left: rem-calc(10);

          div {
            margin-bottom: rem-calc(4);
          }
        }

        .infoDispoActuel {
          margin-top: rem-calc(20);
        }

        .imgDownload {
          position: relative;
          left: rem-calc(11);
          bottom: rem-calc(3);
        }

        .margeInfo {
          margin-bottom: rem-calc(20);
        }

        .titreFormulaire {
          margin-bottom: rem-calc(25);
        }

        p {
          color: $yes-color-text;

          b {
            font-weight: 600;
          }
        }

        .txt-transmis {
          text-align: center;
          color: mediumseagreen;
        }

        .txt-transmis-error {
          text-align: center;
          color: #ed365a;
        }

        .img-illu-documents {
          position: relative;
          left: 50%;
          width: 60%;
        }

        .changer-page-info {
          padding-top: 20px;

          img {
            opacity: 0.4;
            pointer-events: none;
          }

          .info-precedent {
            transform: rotate(180deg);
            position: relative;
            bottom: 1px;
          }

          .info-suivant {
            margin-left: rem-calc(25);
          }
        }

        .enable-info-precedente .info-precedent,
        .enable-info-suivante .info-suivant {
          opacity: 1;
          cursor: pointer;
          pointer-events: all;
        }
      }
    }

    .content-dossier.content-dossier-info {
      background-image: url('/assets/img/illustrations/yes/illu-documents.svg');
      background-repeat: no-repeat;
      background-position-y: 100%;
      background-position-x: 97%;
      padding: 2.38889rem;
      text-align: left;
      height: auto;
      background-size: 30%;

      p a {
        font-size: rem-calc(18);
      }
    }
  }
}

.anciennete-step {
  background-color: white;
  padding: 0.5rem;
  margin: 0.5rem 0;
}

.contribution-line {
  display: flex;
  align-items: center;
}

.ajout-actionnaire {
  cursor: pointer;
  img {
    padding-right: 0.5rem;
  }
}

.ajout-beneficiaire {
  cursor: pointer;
}

.suppression-actionnaire {
  cursor: pointer;
  img {
    padding-left: 0.5rem;
  }
}

.ajout-beneficiaire-button {
  color: #425668;
  border: 1px solid black;
  padding: 0.6rem 0.5rem;
  background: linear-gradient(0deg, lightGrey 0%, transparent 33%);
  cursor: pointer;
  img {
    padding-right: 0.5rem;
  }
}

.edition-beneficaire {
  border: 1px solid black;
  padding: 0.3rem 0.3rem;
  background: linear-gradient(0deg, lightGrey 0%, transparent 33%);
  cursor: pointer;
  h4 {
    margin-bottom: 0;
  }
  .edition-beneficiaire-icon {
    margin-right: 5px;
  }
}

.suppression-bénéficiaire {
  margin-top: 0.3rem;
  text-align: center;
  img {
    height: 12px;
    padding-left: 0.5rem;
  }
  button {
    padding: 0rem 0.3rem 0.2rem 0.3rem;
    background-color: #ec1d1d;
    cursor: pointer;
    img {
      padding: 0;
    }
  }
}

.kyc-class {
  .fields-area {
    margin-bottom: 1.5rem;
  }

  .checkBox {
    margin-bottom: 1rem;
  }

  .text-field {
    margin-top: 0.5rem;
  }

  .labelYesNo {
    color: $yes-color-text;
  }
}

.no-margin-top-dropdown {
  .divDropdown {
    margin-top: 0;
  }
}

// MODAL Bénéficiaires Effectifs
.beneficiaire-modal {
  display: flex;
  padding-top: 2rem;

  .beneficaire-modal-header {
    z-index: 50;
    position: fixed;
    top: 4rem;
    left: 12rem;
    right: 12rem;
    text-align: center;
    color: white;
    background-color: #752127;
    padding: 0.4rem;
    min-width: 400px;

    .beneficaire-modal-closing {
      position: absolute;
      right: 12px;
      cursor: pointer;
      height: 16px;
      top: 10px;
    }
  }

  .beneficiaire-modal-content {
    padding: 1rem 2rem 2rem;
    margin-right: 0.2rem;
  }

  .divDropdown {
    margin-top: 2rem;

    .dropdown {
      width: 100%;
      background-position-x: 97%;
    }
  }

  .separateur {
    margin-bottom: rem-calc(40);
  }
}

// ECRAN Recapitulatif tunnel
.recapitulatifTunnel {
  .loader-content {
    display: flex;
  }
}

.force-siret {
  margin-top: 3rem;

  button {
    margin-top: 1rem;
    background-color: $yes-primary-color;

    &:hover {
      background-color: $yes-primary-color;
    }
  }
}

.garantiesPrevoyance {
  .dateEffet {
    margin-bottom: 2rem;
    .inputText {
      width: rem-calc(275);
    }
  }
  .periodicite {
    margin-top: 2rem;
    .divDropdown {
      width: rem-calc(275);
    }
  }

  .checkBoxContainer {
    padding-left: 3rem;
    padding-top: 2rem;

    .divCheckbox {
      margin-bottom: 0.5rem;
    }
  }

  .titreFormulaire {
    margin-bottom: 1rem;
  }

  .buttonCreationContainer {
    margin-top: 2rem;
  }
}

.fraisContrat {
  .fraisContratCommisionnementBloc {
    margin-bottom: 2rem;
  }
  .tunnelRadioBloc {
    padding-top: 0.5rem;
    padding-left: 2rem;
  }
  .fraisContratSliders {
    display: flex;
    margin-bottom: 2rem;
  }
  .fraisContratSliderBloc {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
    margin-right: 3rem;
    .divInput {
      margin-top: 0;
      padding-left: 1rem;
      max-width: 7rem;
    }
    .yes-label {
      padding-bottom: 0.5rem;
      max-width: 15rem;
      min-width: 15rem;
    }
  }
  .fraisContratSliderBlocInactive {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;
    margin-right: 3rem;
    .divInput {
      margin-top: 0;
      padding-left: 1rem;
      max-width: 7rem;
    }
    .yes-label {
      padding-bottom: 0.5rem;
      max-width: 15rem;
      min-width: 15rem;
    }
    .rangeslider__fill {
      background-color: #d7d7d7;
    }
    .rangeslider-horizontal {
      background-color: #f8f9fb;
    }
    .rangeslider__handle {
      background-color: #eaeaea;
    }
    .rangeslider__handle::after {
      background: #888b8e;
    }
  }
  .administrationFees {
    margin-bottom: 3rem;
    display: flex;
    .divInput {
      margin-top: 0;
      //max-width: 8rem;
    }
    .titreFormulaireGes {
      display: none;
    }
  }
  .fraisContratDerogationBloc {
    max-width: 30rem;
  }
  .fraisContratFraisFixesBloc {
    margin-bottom: 2rem;
  }

  .inputIban {
    .inputEnabled {
      .divInput {
        margin-top: 0.2rem;
      }
    }
  }
}

/* Tablette en mode paysage */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .App-tunnel .cell-content .frise-item img {
    width: rem-calc(15) !important;
  }

  .App-tunnel .cell-content .frise-dossier .frise-item .frise-txt {
    font-size: rem-calc(14);
  }

  .App-tunnel .cell-content .frise-dossier .yes-right-part .yes-info {
    display: inline-block;
    max-width: rem-calc(250);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .App-tunnel .cell-aide .aide-container .aide-info .infos-etape-menu .etape-info-item-menu img {
    width: rem-calc(20);
  }

  .App-tunnel .cell-aide .aide-container .aide-info .infos-etape-menu .etape-info-item-menu span {
    font-size: rem-calc(12);
  }

  .App-tunnel .cell-aide .aide-container .aide-info .infos-etape-menu .etape-info-item-menu .etape-info-fleche {
    width: rem-calc(8);
    top: rem-calc(9);
  }

  // ABONDEMENT
  .App-tunnel .cell-content .content-dossier .formDispositifAbondement .yes-rectangle {
    padding: 20px;
  }

  .App-tunnel .cell-content .content-dossier .formDispositifAbondement .yes-rectangle .contentAbondementActive,
  .App-tunnel
    .cell-content
    .content-dossier
    .formDispositifAbondement
    .yes-rectangle
    .contentAbondementActive
    .tauxEtPlafondAbondement {
    padding: 0 !important;
  }

  .App-tunnel
    .cell-content
    .content-dossier
    .formDispositifAbondement
    .yes-rectangle
    .contentAbondementActive
    .checkboxMiseEnPlaceAbondement {
    padding-left: 16px;
    padding-top: 10px;
  }
}

/* Tablette en mode portait */
@media screen and (min-width: 481px) and (max-width: 768px) {
}

@media screen and (max-width: 1550px) {
  p {
    font-size: rem-calc(18);
  }
  .App-tunnel .cell-content .content-dossier .tunnel-infos-container-child .etape-apres-signature .icon-check-ok {
    bottom: rem-calc(10);
    left: rem-calc(2);
  }
}
@media screen and (max-width: 1550px) and (max-height: 690px) {
  html {
    font-size: 15px;
  }
}

// CSS FOR MOBILE
@media screen and (max-width: 480px) {
  body {
    background: white;
  }

  .App-tunnel .cell-content .content-dossier .buttonCreationContainer {
    margin-bottom: 12px;
  }

  .menu-mobile-tunnel {
    height: 60px;
    background: $yes-primary-color;

    img {
      margin-top: 12px;
      margin-left: 13px;
    }
  }

  .App-tunnel {
    form {
      overflow-x: hidden;
    }

    .cell-content .content-dossier {
      padding: 0 !important;
      width: 90%;
      margin: 0 auto;
    }
  }
}

@media screen and (max-height: 450px) {
  .tunnel-container .tunnel-ctn .buttonCreationContainer {
    position: relative;
    bottom: 0;
    width: 100%;
  }
}

// Modal
// ----------------------

.modal {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll;
}

.modal-dialog {
  display: block;
  z-index: 1006;
  backface-visibility: hidden;
  padding: 1rem;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}

.close-button {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
  cursor: pointer;
}

.btn-modal {
  background: #752127;
  border: #752127 solid 1px;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 20px;
  padding: 8px 15px;
  text-decoration: none;
  text-align: center;
  min-width: 60px;
  position: relative;
  transition: color 0.1s ease;
  cursor: pointer;

  &:hover {
    background: rgba(117, 33, 39, 0.8);
  }
}

.blackLabel {
  color: #425668;
}

.modal-body {
  padding: 20px;
}

.modal-header,
.modal-footer {
  padding: 10px 20px;
}

.modal-header {
  h2 {
    font-size: 22px;
  }
}

.modal-footer {
  text-align: right;
}
