// Theme
@import './fonts.scss';
@import './themes/default-theme.scss';
@import './utilities';
@import 'src/scss/app/tenants/ges/_ges-variables.scss';

/* default theme variables css */
:root {
  /* colors */
  --cn-color-grey-100: #{$cn-color-grey-100};
  --cn-color-grey-150: #{$cn-color-grey-150};
  --cn-color-grey-200: #{$cn-color-grey-200};
  --cn-color-grey-400: #{$cn-color-grey-400};
  --cn-color-high-emphasis: #{$cn-color-high-emphasis};
  --cn-color-low-emphasis: #{$cn-color-low-emphasis};
  --cn-color-mask-contrasted: #{$cn-color-mask-contrasted};
  --cn-color-medium-emphasis: #{$cn-color-medium-emphasis};
  --cn-color-primary-opacity-14: #{$cn-color-primary-opacity-14};
  --cn-color-primary-opacity-08: #{$cn-color-primary-opacity-08};
  --cn-color-primary-opacity-04: #{$cn-color-primary-opacity-04};
  --cn-color-primary-opacity-1: #{$cn-color-primary-opacity-1};
  --cn-color-primary: #{$cn-color-primary};
  --cn-color-secondary: #{$cn-color-secondary};
  --cn-color-valid-20: #{$cn-color-valid-20};
  --cn-color-valid: #{$cn-color-valid};
  --cn-color-warning-200: #{$cn-color-warning-200};
  --cn-color-warning: #{$cn-color-warning};
  --cn-color-black: #{$cn-color-black};
  --cn-color-white: #{$cn-color-white};
  --cn-ges-input-bg-color: #{$yes-input-background-color};

  /* fonts */
  --cn-font-family-playfair-display-serif: #{$font-family-playfair-display-serif};
  --cn-font-family-source-pro-sans-serif: #{$font-family-source-pro-sans-serif};

  /* Typographie */
  --cn-typo-body-font-size: #{$cn-typo-body-font-size};
  --cn-typo-default-color: #{$cn-typo-default-color};
  --cn-typo-font-size-large: #{$cn-typo-title-font-size-large};
  --cn-typo-font-size-medium: #{$cn-typo-title-font-size-medium};
  --cn-typo-font-size-small: #{$cn-typo-title-font-size-small};
  --cn-typo-font-size-tiny: #{$cn-typo-title-font-size-tiny};
  --cn-typo-primary-color: #{$cn-color-primary};
  --cn-typo-title-font-weight-600: #{$cn-typo-title-font-weight-600};
  --cn-typo-warning-color: #{$cn-typo-warning-color};
  --cn-typo-in-filled-input: #{$yes-gris1};

  /* css rule to make i18next line break available in react app */
  white-space: pre-line;
}
