/* Désactiver le jaune par défaut de CHROME lorsque l'on pointe sur un champt INPUT */
input:-webkit-autofill {
  background-color: #f8f9fb !important;
}

input:-webkit-autofill {
  background-color: red !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f8f9fb inset;
}

/* suppression du curseur pour choisir un nombre */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* disable placeholder s'ils sont activés lorsque le champ n'est pas focus */
.divInput {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: transparent;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: transparent;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: transparent;
  }
}

/* enabled placeholder s'ils sont activés lorsque le champ est focus */
.divInput.activePlaceholder {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: inherit;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: inherit;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: inherit;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: inherit;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

/* suppression du contour bleu de chrome */
:focus {
  outline: 0;
}

.inputWithoutRedux {
  display: inline-block;
}

.inputEnabled {
  position: relative;
}

.fakeInputChrome {
  position: absolute;
  height: 0;
}
.fakeInputChrome input {
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
  pointer-events: none;
}

.divInput {
  position: relative;
  margin-top: rem-calc(38);

  .inputText {
    font-size: rem-calc(18); /* font-size is up to you, not required */
    padding: rem-calc(10); /* padding is up to you */
    display: inline-block; /* just to make sure display is block */
    width: 100%; /* width is up to you */
    height: rem-calc(45);
    background: $yes-input-background-color;
    border: none;
    border-bottom: 1px solid $yes-input-border-bottom;
    font-size: rem-calc(18);
    line-height: 1.5;
    color: $yes-gris1;
    font-weight: normal;
    outline: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .inputText:focus {
    border-bottom: 1px solid $yes-primary-color;
  }

  .label-input {
    letter-spacing: 1px;
    position: absolute;
    font-weight: normal; /* up to you, not required */
    left: rem-calc(10); /* determines space to left of label */
    top: rem-calc(13); /* determines space above label */
    pointer-events: none; /* enables click through on label */
    text-transform: uppercase;
    color: #728d9c;
    line-height: 1.5;
    font-size: rem-calc(14);
    transition: 0.2s ease all; /* determines animation during */
    -moz-transition: 0.2s ease all; /* user interaction */
    -webkit-transition: 0.2s ease all;

    &.label-color {
      color: $yes-primary-color;
    }
  }

  .inputText:focus ~ label,
  .inputText.hasValue ~ label {
    top: rem-calc(-16); /* where the label goes upon interaction */
    font-size: rem-calc(12) !important; /* font-size change on interaction */
  }

  .inputEuro {
    padding-right: rem-calc(25);
  }

  .euroSign {
    position: absolute;
    bottom: rem-calc(10);
    right: rem-calc(7);
    font-size: rem-calc(19);
    color: $yes-color-text;
  }

  .pourcentSign {
    position: absolute;
    bottom: rem-calc(10);
    right: rem-calc(7);
    font-size: rem-calc(19);
    color: $yes-color-text;
  }
}

.inputDisabled {
  label,
  input {
    cursor: default;
    border-bottom: none !important;
  }
  input {
    pointer-events: none;
  }
}

.divInput.errorInput {
  label.label-input {
    color: #ed365a !important;
  }
  input.inputText {
    border-bottom: 1px solid #ed365a !important;
  }
}

.errorServerFormHide {
  .customErrorMsg {
    display: none;
  }
  .divInput.errorInput input.inputText {
    border-color: #728d9c !important;
  }
  .divInput.errorInput.inputValid input.inputText {
    border-color: $yes-primary-color !important;
  }
  .divInput.errorInput label.label-input {
    color: #728d9c !important;
  }
}

.divInput.inputValid input {
  border-color: $yes-primary-color;
}

.errorMsgInput {
  font-family: SourceSansPro;
  font-size: rem-calc(14);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ed365a;
  font-family: $font-text;
  position: absolute;
  width: 100%;

  a {
    color: #ed365a;
    text-decoration: underline;
  }
}

// PASSWORD
.eyeContainer {
  position: absolute;
  right: rem-calc(16);
  bottom: rem-calc(11);

  img {
    cursor: pointer;
  }
}

.conditionPassword {
  text-align: right;
  display: block;
  font-family: $font-text;
  font-size: rem-calc(14);
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: $yes-gris1;
  padding-top: 2px;
  padding-bottom: rem-calc(12);
  position: relative;
  height: rem-calc(34);

  a {
    color: $yes-gris1;
  }

  a:hover {
    text-decoration: none;
    color: $yes-gris1-hover;
  }
}

// CHECKBOX
.label-checkbox {
  font-family: $font-text;
  font-size: rem-calc(16);
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $yes-gris1;
  margin-left: rem-calc(11);
  position: relative;
  bottom: 1px;
  font-style: italic;
  cursor: pointer;
}

.errorCheckbox {
  input {
    outline: 1px solid #ed365a;
  }
  label.label-checkbox {
    color: #ed365a;
  }
}

.yes-tooltip {
  text-align: right;

  .tooltip-container {
    position: relative;

    .input-tooltip {
      display: none;
      position: absolute;
      bottom: rem-calc(19);
      text-align: right;
      right: rem-calc(-109);
      box-shadow: 4px 4px 3px 0 rgba(0, 0, 0, 0.1);
      background-color: #f7fbfb;
      border-radius: 4px;
      padding: rem-calc(10.9);
      font-size: rem-calc(14);
      font-family: $font-text;
      font-weight: 300;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $yes-color-text;
      text-align: left;
      z-index: 1;
      max-width: rem-calc(241.6);
      width: rem-calc(241.6);
    }

    .input-tooltip:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      bottom: -0.8em;
      left: 50%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 0.5em solid black;
      border-color: transparent transparent #f7fbfb #f7fbfb;
      -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-box-shadow: 4px 4px 3px 0 rgba(0, 0, 0, 0.1);
      box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .tooltipSpan {
    cursor: pointer;
  }
}

.yes-tooltip.tooltipNoForm {
  display: inline;
  margin-left: 3px;

  .tooltip-container {
    display: inline;
  }

  .input-tooltip {
    bottom: rem-calc(36);
    right: rem-calc(-127);
  }
  .tooltipSpan {
    bottom: 2px;
    position: relative;
  }
}

// CHOIX
.yes-circle {
  width: 20px;
  height: 20px;
  border: solid 2px #eaecef;
  border-radius: 50%;
  background: $yes-primary-color;
  display: inline-block;
  position: relative;

  .circlebuttonRadio {
    width: 8px;
    height: 8px;
    background: $yes-primary-color;
    display: block;
    border-radius: 50%;
    top: 4px;
    position: relative;
    left: 4px;
  }

  .icon-check,
  .icon-check-checked-mode {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
    bottom: 3px;
    position: absolute;
    left: 3px;
  }

  .choixObj {
    position: absolute;
    width: rem-calc(22);
    height: rem-calc(20);
    opacity: 0;
    z-index: 9;
    cursor: pointer;
  }
}

.yes-circle.whiteCircleBackground.whiteCircleBackground {
  background-color: white;
}

.yes-circle.noChecked.modeCheckedRadio {
  background: $yes-primary-color;
}

label.labelChoix {
  font-family: $font-text;
  font-size: rem-calc(18);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #89969f;
  position: relative;
  bottom: rem-calc(6);
  left: rem-calc(5);
  padding-right: rem-calc(18);
  cursor: pointer;
}

.gesLabelChoix {
  font-family: $font-text;
  font-size: rem-calc(18);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #89969f;
  position: relative;
  left: rem-calc(10);
  padding-right: rem-calc(18);
  cursor: pointer;
}

.disableChoix {
  opacity: 0.4;
  pointer-events: none;
  cursor: initial;

  input {
    cursor: initial;
  }
}

.yes-circle.noChecked {
  background: white;

  .icon-check {
    display: none;
  }
}

label.label-checkbox-v2 {
  font-family: $font-text;
  font-size: rem-calc(18);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $yes-color-title;
  position: relative;
  bottom: 4px;
  padding-left: rem-calc(10);
  cursor: pointer;
}

// CHECKBOX V2
.divCheckbox {
  // position: relative;

  .yes-checkbox {
    display: inline-block;
    width: rem-calc(17);
    height: rem-calc(17);
    border: solid 1px $yes-checkbox-border;
    cursor: pointer;
    border-radius: 2px;
    position: relative;
    z-index: 0;
    background: white;

    input {
      opacity: 0;
      cursor: pointer;
      z-index: 99;
      width: rem-calc(17);
      height: rem-calc(17);
      bottom: rem-calc(3);
      position: absolute;
    }
  }

  .icon-check {
    width: rem-calc(13);
    height: 16px;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
    bottom: -2px;
    position: absolute;
    right: rem-calc(1);
    cursor: pointer;
  }
}

.divCheckbox.noChecked {
  .icon-check {
    display: none;
  }
}

.divCheckbox.checked {
  .yes-checkbox {
    background: $yes-primary-color;
  }
  .yes-checkbox.disableCheckbox {
    background: $yes-checkbox-disabled-color;
  }
}

// DROPDOWN
.divDropdown {
  position: relative;

  label.labelDropDown {
    font-family: $font-text;
    font-size: rem-calc(12);
    font-style: normal;
    font-stretch: normal;
    color: $yes-primary-color;
    letter-spacing: 1px;
    position: absolute;
    font-weight: normal;
    pointer-events: none;
    text-transform: uppercase;
    line-height: 1.5;
    top: rem-calc(-16);
    left: rem-calc(7);
  }

  select {
    width: auto;
    padding: rem-calc(5) rem-calc(35) rem-calc(5) rem-calc(5);
    height: rem-calc(34);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: rem-calc(18); /* font-size is up to you, not required */
    padding: rem-calc(10); /* padding is up to you */
    display: block; /* just to make sure display is block */
    height: rem-calc(45);
    background: rgba(237, 241, 244, 0.4);
    background-image: url('/assets/img/icons/yes/down-chevron-select.svg');
    background-size: rem-calc(10);
    background-position-y: rem-calc(18);
    background-position-x: rem-calc(54);
    background-repeat: no-repeat;
    border: none;
    border-bottom: 1px solid $yes-primary-color;
    font-size: rem-calc(18);
    line-height: 1.5;
    color: $yes-gris1;
    font-weight: normal;
    outline: none;
    display: inline-block;
    width: auto;
    min-width: rem-calc(70);
    width: auto;
  }
}
/* CAUTION: IE hackery ahead */
select::-ms-expand {
  display: none; /* remove default arrow on ie10 and ie11 */
}
/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
  select {
    background: none\9;
    padding: 5px\9;
  }
}

// CSS FOR MOBILE
@media screen and (max-width: 480px) {
  .conditionPassword {
    padding-bottom: 0px;
    text-align: inherit;

    .deplacementMdpOublie {
      margin-top: rem-calc(33);
      display: inline-block;
    }

    .indicationPassword {
      margin-top: rem-calc(23);
      display: inline-block;
      margin-top: rem-calc(23);
    }

    .mdpOublie {
      text-align: right;
      display: inline-block;
      width: 100%;
    }
  }

  .divInput {
    margin-top: rem-calc(30);
  }

  .divCheckbox {
    margin-top: rem-calc(25);

    .label-checkbox {
      font-size: rem-calc(14);
    }
  }

  .errorMsgInput {
    font-size: rem-calc(12);
    max-width: 100%;
  }
}
