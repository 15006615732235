.App-tunnel .cell-aide .aide-container .yes-logo-dossier .logoDossierYomoni {
  width: 55%;
}

.App-tunnel .cell-content.background-ges {
  background-image: url("/assets/img/logos/logo_fond_generali_tunnel.svg");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: 115%;
  background-size: 30%;
}

@font-face {
  font-family: "HelveticaNeueCn";
  src: url("/assets/fonts/HelveticaNeueLTStd-Cn.otf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeueLt";
  src: url("/assets/fonts/HelveticaNeueLTStd-Lt.otf") format("truetype");
}
